#BoxCabeceraTablasGestion{
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

#BoxIconoTituloBuscarFiltros{
    height: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--6, 48px);
}

#BoxIconoTitulo{
    height: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#BoxIconoCabecera{
    width: 28px;
    height: 24px;
    padding: 14px 14px;
}


#BoxTituloCabecera{
    width: auto;
    height: 48px;
    display: flex;
    align-items: center;
}

#txtTitulo{
    color: #333;
    font-family: inherit;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
}


#BoxSearchBarCabeceraTablaGU{
    width: auto;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: var(--6, 48px);
}

#BoxTextFieldSearchbar{
    width: 248px;
    height: 40px;
}

#BoxBotonesGestiones{
    width: auto;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

#BoxContenedorIconoOpcion{
    width: 48px;
    height: 48px;
    padding: 0px;
}

#btnIconoAccionCabecera{
    width: 48px;
    height: 48px;
    padding: 0px;
}


#iconoCabecera{
    width: 48px;
    height: 48px;
    padding: 0px;
    color: #175676;
}

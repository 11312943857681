#ContainerAgregarTareaActuador{
    /* height: 1000px;
    width: 1000px; */
    width: 350px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px 30px 30px 30px;
    justify-content: center;
    align-items: center;
    border-radius: 90px;
}

#BoxTituloAgregarTareaActuador{
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #B1B1B1;
    margin-bottom: 10px;
}

#TxtTituloAgregarTareaActuador{
    color: #333;
    font-family: inherit;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    display: flex;
    justify-content: center;
}

#BoxCuerpoAgregarTareaActuador{
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 20px;
}

#BoxGestionarActuador{
    display: flex;
    flex-direction: row;
    gap: 30px;
}

#BoxEnviarCorreo{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

#BoxEnviarNotificacionPush{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

#BoxBotonEnviarTareaActuador{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
}
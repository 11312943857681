#BoxCabeceraDataUsuario{
    width: 792px;
    height: 48px;
    padding: 4px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-self: stretch;
}

#BoxContenedorTextField{
    width: 100%;
    height: 44px;
}


#BoxBodyDataUsuario{
    width: 680px;
    display: flex;
    align-items: flex-start;
    gap: 60px;
}

#BoxDataDelUsuario{
    width: 368px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#BoxCorreoPerfil{
    width: 100%;
    height: 68px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

#BotonEditarCorreoPerfilPerfil{
    text-transform: none;
    color: #626262;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
    border-radius: 4px;
    margin-top: 4px;
    height: 20px;
}

#IconoEditarCorreoPerfil{
    width: 12px;
    height: 12px;
    padding: 3px;
}


#BoxBotonEnviarInformacion{
    width: 100%;
    min-height: 48px !important;
}
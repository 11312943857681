#ContainerMenuNotificaciones {
    width: 350px;
    height: 450px;
    border-radius: 16px;
    padding: 0px;
}

#MenuNotificacionHeader{
    width: 350px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

#BoxTituloNotificacionescomponente{
    width: 300px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

#TextoTituloNotificaciones{
    padding-left: 20px;
    color: #333;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
}

#BoxIconoAccionCierre{
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
#CerrarMenuNotificacionesIcon{
    width: 12px;
    height: 12px;
    padding: 8px;
}


#BoxCuerpoNotificaciones{
    width: 310px;
    height: 378px;
    padding: 0px 20px 20px 20px;
}

#BoxContenidoNotificaciones{
    width: 310px;
    height: 378px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#BoxListaNotificaciones{
    width: 310px;
    min-height: 348px;
    max-height: 348px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
    gap: 10px
}

#BoxContenidoItemNotificacion{
    width: 300px;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 8px;
    box-shadow:  "0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)";
}

#BoxListaNotificaciones::-webkit-scrollbar{
    width: 7.024px !important;
    background: transparent !important;
} 

#BoxListaNotificaciones::-webkit-scrollbar-track{
    background: transparent !important;
}

#BoxListaNotificaciones::-webkit-scrollbar-thumb{
    background: #C9C9C9;
    border-radius: 100px;
}


#BoxItemNotificacion{
    width: 250px;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    padding-left: 25px;
}

#BoxContenidoItemNotificacion:hover{
    background-color: rgba(76, 76, 76, 0.08);
    cursor: pointer;
}

#BoxIconButtonTresPuntosItemNotificaciones{
    width: 40px;
    height: 40px;
}

#IconButtonItemNotificaciones{
    width: 16px;
    height: 16px;
    padding: 4px;
}


#TxtMensajeNotificacion{
    width: 220px;
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

#ContainerMenuNotificaciones {
    width: 100%;
    border-radius: 16px;
}

#BoxMenuNotificaciones{
    overflow-y: auto;
    max-height: 410px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

#DescripcionNotificacion{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

#TxtFechaNotificacion{
    color: #626262;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
}


#BoxCantidadNotificaciones{
    width: 310px;
    height: 30px;
    order: 1px solid black;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#BoxLayoutPases{
    width: 894px;
    height: 530px;
    display: flex;
    padding: var(--none, 0px) var(--8, 64px) 52px var(--none, 0px);
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: var(--6, 48px);
}

#BoxTituloPases{
    display: flex;
    width: 894px;
    height: 40px;
    padding-bottom: 16px;
    align-items: flex-start;
    gap: 8px;
    border-bottom: 1px solid #B1B1B1;
    flex: 1 0 0;
}

#TxtTituloPases{
    color: #333;
    font-family: inherit;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
}

#BoxTablaPases{
    display: flex;
    width: 893px;
    height: 424px;
    flex-direction: column;
    align-items: flex-start;
    gap: 68px;
}


#DataGridMetodosIdentificacion{
    width: inherit;
    height: inherit;
}

#BoxLayoutFormAgregarNuevoPaseGU{
    width: 896px;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 88px;
}

#BoxFormAgregarNuevoPaseGU{
    width: 896px;
    height: 264px;
    display: flex;
    align-items: flex-start;
    gap: 35px;
}

#BoxTituloFormAgregarNuevoPaseGU{
    width: 181px;
    height: 264px;
}

#TxtTituloFormAgregarNuevoPaseGU{
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    padding-bottom: 32px;
}


#BoxDataToCreateAgregarNuevoPaseGU{
    width: 680px;
    height: 264px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: var(--6, 48px);
}

#BoxDataToCreateTipoMetodoAgregarNuevoPaseGU{
    width: 680px;
    height: 56px;
    display: flex;
    align-items: flex-start;
    gap: var(--5, 40px);
}

#BoxTipoAccesoAgregarNuevoGU{
    display: flex;
    width: 320px;
    height: 56px;
    flex-direction: column;
    align-items: flex-start;
}
#BoxTipoMetodoAgregarNuevoGU{
    display: flex;
    width: 320px;
    height: 56px;
    flex-direction: column;
    align-items: flex-start;
}

#BoxDataToCreatePatanteTarjetaAgregarNuevoPaseGU{
    width: 680px;
    height: 56px;
    display: flex;
    align-items: flex-start;
    gap: var(--5, 40px);
}

#BoxPatenteAgregarNuevoGU{
    display: flex;
    width: 320px;
    height: 56px;
    flex-direction: column;
    align-items: flex-start;
}

#BoxTarjetaAgregarNuevoGU{
    display: flex;
    width: 320px;
    height: 56px;
    flex-direction: column;
    align-items: flex-start;
}




#BoxAccionesAgregarNuevoPaseGU{
    display: flex;
    width: 896px;
    height: 48px;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 16px;
}

#BoxBtnCancelarAgregarNuevoPaseGU{
    width: 152px;
    height: 48px;
}

#BoxBtnAgregarAgregarNuevoPaseGU{
    width: 152px;
    height: 48px;
}


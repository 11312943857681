#BoxCabeceraAgenda{
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

#BoxContenedorCabeceraAgenda{
    width: inherit;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

#BoxIconoTituloHoyCabecerAgenda{
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
}



#BoxBotonNuevoEventoAgenda{
    width: 112px;
    height: 42px;
}


#BoxIconoCabeceraAgenda{
    width: 48px;
    height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#IconoCabeceraAgenda{
    width: 24px;
    height: 24px;
}



#BoxContenedorOpcionesAgenda{
    width: 376px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

#BoxOpcionSelectedAcciones{
    width: 376px;
    height: 48px;
}

#BoxSelectOpcionCabeceraAgenda{
    width: 130px;
    height: 42px;
}



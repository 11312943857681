#BoxContenedorHorasDiaListaSinEventos{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #94B1C0;
}

#BoxContenedorHorasDiaListaConEventos{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    padding-left: 5px;
    padding-bottom: 5px;
    padding-right: 5px;
    border: 1px solid #94B1C0;
}

#BoxMultipleEventosDia{
    width: 98%;
    height: 100%;
    border: 1px solid #94B1C0;
    border-radius: 8px;
    background: #5693b3;
    box-shadow:0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
    cursor: pointer;
   
}


#BtnBoxMultipleEventos{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}


#TxtNombreEventoVistaDiaAgenda{
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
}

#BoxDataEventoDiarioVistaDia{
    width: 100%;
    height: 100%;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2px;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
}

#BtnNombreEventoDataMultiple{
    height: 100%;
}


#BoxTituloMultiple{
    text-align: center;
}

#BoxDataEventoDiario{
    width: 200px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    border: 1px solid #94B1C0;
    border-radius: 8px;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
    cursor: pointer;
    height: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#txtInfoEventoTitulo{
    color: #0d2f40;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; 
    letter-spacing: 0.15px;
}
#BoxLayoutVistaSemana{
    width: inherit;
    height: 616px;
}


#BoxVistaCalendarioSemana{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    max-height: 616px;
    overflow-y: scroll;
    background: transparent;
}

#BoxVistaCalendarioSemana::-webkit-scrollbar{
    width: 7.024px !important;
    background: transparent !important;
} 

#BoxVistaCalendarioSemana::-webkit-scrollbar-track{
    background: transparent !important;
}

#BoxVistaCalendarioSemana::-webkit-scrollbar-thumb{
    background: #C9C9C9;
    border-radius: 100px;
}

#ContenedorHoras{
    width: 100px;
    height: 100%;
}


#GridItemAccion{
    width: 100px;
    height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#HoraDia{
    width: 100px;
    height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #94B1C0;
}

#Test{
    width: 100%;
    height: 48px;
}

#GridContenedorDiaSemana{
    width: 190.3px;
    height: calc(48*24px);
    flex: 1 0;
}

#GridNombreDiaVistaSemana{
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #94B1C0;
}

#GridDiaVistaSemana{
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#GridListaDeHorasDiaSemana{
    width: 100%;
    height: 100%;
}


#EspacioLibreVistaSemana{
    width: 100px;
    height: 48px;
    border-bottom: 1px solid #94B1C0;
}


#GridNombreDiaVistaSemana{
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
}


#TxtNumeroDiaVistaSemana{
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
}
#BoxCabeceraMiembrosUbicacion{
    width: 992px;
    height: 48px;
    display: flex;
    padding: var(--2, 16px) var(--2, 16px) var(--none, 0px) var(--2, 16px);
    justify-content: flex-end;
    align-items: center;
    gap: var(--3, 24px);
    align-self: stretch;
}

#BoxInformacionMiembrosUbicacion{
    width: 896px;
    height: 600px;
    display: flex;
    padding: var(--none, 0px) var(--8, 64px) var(--8, 64px) var(--8, 64px);
    flex-direction: column;
    align-items: flex-start;
}

#BoxTituloOpcionMiembrosUbicacion{
    width: 896px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #333;
}


#BoxTablaMiembros{
    width: 896px;
    height: 447px;
}

#BoxFormNuevoMiembro{
    width: 260px;
    height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 20px 20px 20px;
    gap: 20px;
}

#BoxCabeceraNuevoMiembro{
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

#TxtCabeceraNuevoMiembro{
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
}

#BoxSeleccionadorNuevoUsuarioUbicacion{
    width: 260px;
    height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 165px;
}

#BoxAutocompleteUsuarios{
    width: 260px;
    height: 48px;
    max-height: 48px;
}

#BoxBotonAgregar{
    width: 100%;
    height: 48px;
}
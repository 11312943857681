#ContenerMenuOpciones{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: var(--1, 8px);
    align-self: stretch;
}

#ButonCampana{
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#BotonNotificaciones{
    width: inherit;
    height: inherit;
}

#MediaBox{
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#profileIMG{
    width: 40px;
    height: 40px;
}

#BoxHelper{
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#BotonFlechaAbajo{
    width: 56px;
    height: 56px
}

#FlechaOpcionesNavegacion{
    width: 16px;
    height: 8px;
    padding: 12px 16px
}
#BarraNavegacion{
    display: flex;
}

#TBBNavegacion{
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    padding: 0px;
}

#AreaMenu{
    flex: 25%;
}

#Buscador{
    flex: 50%;
    margin-left: 560px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
}

#MenuUsuarios{
    flex: 25%;
    padding-right: 1%;
    height: 56px;
}


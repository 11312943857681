#BoxLayoutVistaAnio{
    width: inherit;
    height: 616px;
}

#BoxVistaAniosCalendarios{
    width: 100%;
    height: 100%;
}

#BoxContenedorMesesVistaAnios{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 5px;
    min-width: 1550px;
    overflow-x: hidden;
    flex: 1;
}




#BoxContenedorNombreMes{
    width: 70px;
    height: 100%;
    writing-mode: vertical-lr;
    transform: rotate(-180deg);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

#BoxContenedorSemanasDias{
    width: 256px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#BoxContenedorGridDiasSemanaVistaAnio{
    width: 100%;
    height: 30px;
    border-bottom: 1px solid #94B1C0;
}

#BoxNombreDiasSemanaOrdenadosVistaAnio{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#DiaSemanaCortosVistaAnio{
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}






#BoxContenedoDia{
    width: 40px;
    height: inherit;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#TxtNombreDiaSemanaVistaAnio{
    color: #103D54;
    font-family: inherit;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.5px;
}




#TxtNombreMesVistaAnio{
    color: #175676;
    font-family: inherit;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
}


#BoxGeneralContenedorMesVistaAnio{
    width: 320px;
    height: 180px;
    background: #FFF;
    border: 1px solid #94B1C0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px;
    align-items: center;
    border-radius: 10px;
}


#BoxGeneralContenedorMesVistaAnio:hover{
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
}

#BoxContenedoMesCompletoVistaAnio{
    width: 256px;
    height: 167px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 2.3px;
}

#BoxContenedorDiasDelMes{
    width: 256px;
    height: 100%;
    display: flex;
    flex-direction: column;
    
}

#BoxRowDias{
    width: 100%;
    padding: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#btnToDiaView{
    width: 30px;
    height: 22.3px;
    min-width: 30px;
    padding: 0px;
}

#TxtItemDiaGridVistaAnio{
    padding: 0px;
    font-family: inherit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
}
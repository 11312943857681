#LayoutMapa{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
}

#TestGrid{
    position: absolute;
    top: 0;
    left: auto;
    right: auto;
    width: 1000px;
    bottom: 0;
    height: 100%;
    background: transparent;
}

#PruebaButton{
    top: auto;
    right: auto;
    left: auto;
    bottom: auto;
}

#IconoEnMapa{
    width: 50px;
    height: 50px;
    color: red;
}
#CabeceraNuevaTarjeta{
    width: 992px;
    height: 48px;
    display: flex;
    padding: var(--2, 16px) var(--2, 16px) var(--none, 0px) var(--2, 16px);
    justify-content: flex-end;
    align-items: center;
    gap: var(--3, 24px);
    align-self: stretch;
}
#ContenidoModalNuevaTarjeta{
    width: 896px;
    height: 552px;
    display: flex;
    padding: var(--none, 0px) var(--8, 64px) var(--8, 64px) var(--8, 64px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--5, 40px);
}

#BoxContenidoInternoModalNuevaTarjeta{
    width: 896px;
    height: 552px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--6, 48px);
}




#BoxTitutloModalNuevaTarjeta{
    width: 896px;
    height: 40px;
    display: flex;
    padding-bottom: 16px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-bottom: 1px solid #B1B1B1;
}

#TituloNuevaTarjeta{
    color: #333;
    font-family: inherit;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 125% */
}

#BoxSubtituloModalNuevaTarjeta{
    height: 20px;
    display: flex;
    width: 896px;
    padding: 10px 0px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

#SubtituloNuevaTarjeta{
    color: #8A8A8A;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
}

#BoxContenedorDataNuevaTarjeta{
    width: 896px;
    height: 408px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 200px;
}

#BotoncesAccionModalNuevaTarjeta{
    display: flex;
    width: 896px;
    height: 48px;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 16px;
}


#BoxBotonNuevaTarjeta{
    width: 152px;
    height: 48px;
}


#BoxTituloFormularioNuevaTarjeta{
    width: 896px;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: var(--5, 40px);
}

#BoxTituloNuevaTarjeta{
    display: flex;
    width: 176px;
    padding-bottom: 32px;
    align-items: center;
}
#TxtTituloInformacionPersonal{
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
}

#BoxFormularioNuevaTarjeta{
    width: 680px;
    height: 160px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--6, 48px);
}

#BoxFormularioTipoTarjetaNueva{
    width: 680px;
    height: 56px;
}

#BoxFormularioCodigoNuevaTarjeta{
    width: 680px;
    height: 56px;
}
#ContainerConfiguracionActuador{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

#BoxTituloConfiguracionActuador{
    width: 896px;
    display: flex;
    height: 40px;
    padding-bottom: 5px;
    justify-content: space-between;
    gap: 10px;
    align-self: stretch;
    border-bottom: 1px solid #B1B1B1;
}

#TxtTituloConfiguracionActuador{
    color: #333;
    font-family: inherit;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
}

#TxtTituloConfiguracionTipoActuador{
    color: #666666;
    font-family: inherit;
    font-size: 29px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
}

#TxtTituloTareasConfiguracionActuador{
    color: #666666;
    font-family: inherit;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    padding-left: 10px;
}

#BoxTituloTareasConfiguracionActuador{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
}
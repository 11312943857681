#BoxContenedorTablaActuadores{
    width: inherit;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;
}

#TablaActuadores{
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; 
    letter-spacing: 0.15px;
}

#FiltroSelectTiposActores{
    width: 100%;
    margin-left: 10px;
}
#boxCambioCorreoValidacion{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 32px;
}

#CardCambioCorreoValidacion{
    width: 624px;
    height: 920px;
    border-radius: 32px;
}

#CardContenidoCambioCorreoValidacion{
    width: 408px;
    height: 488px;
    padding-top: 212px;
    padding-bottom: 220px;
    padding-left: 108px;
    padding-right: 108px;
}

#BoxImagenCambioCorreoValidacion{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


#imagenCambioCorreoValidacion{
    width: 256px;
    height: 152px;
}

#BoxTituloCambioCorreoValidacion{
    width: 100%;
    text-align: center;
    padding-top: 48px;
}

#TextoTituloCambioCorreoValidacion{
    color: #175676;
    text-align: center;
    font-family: inherit;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
}

#BoxBotonConfirmarCambio{
    width: 100%;
    height: 48px;
    padding-top: 46px;
}



#BoxCorreoInformacionCambio{
    width: 100%;
    height: 300px;
    gap: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 30px;
}

#BoxBotonConfirmarCambioCorreo{
    padding-top: 30px;
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#BotonEnCambioDeCorreo{
    width: 200px;
    border-radius: 100px;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
    height: 100%;
    color: #FFF;
    background: #175676;
    text-align: center;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
    text-transform: none !important;
}

#BotonEnCambioDeCorreo:hover{
    border-radius: 100px;
    background: #1F7098;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
    color: #B7CBD5;
    text-align: center;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
}
#ContainerAsistenciaConfirmada{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    height: 800px;
    display: flex;
    border-radius: 16px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.15)";
}


#BoxContenedorIMGCP2AsistenciaConfirmada{
    width: 600px;   
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#CMImagenCP2{
    width: 340px;
    height: 200px;
}

#BoxCabeceraAsistenciaConfirmada{
    width: 600px;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 40px;
}

#BoxTituloMensajeAsistenciaConfirmada{
    width: 600px;
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#TxtTituloAsistenciaConfirmada{
    color: #175676;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
}

#BoxTituloAsistenciaConfirmada{
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#TxtMensajeAsistenciaConfirmada{
    color: #626262;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
}
#BoxLayoutSinDetalleUbicacionGU{
    display: flex;
    width: 896px;
    height: 448px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 80px;
}

#BoxTextoSinDetalleUbicacionGU{
    width: 896px;
    height: 72px;
    text-align: center;
}

#TxtSinDetalleUbicacionGU{
    color: #626262;
    text-align: center;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
}

#BoxContenedorBtnSinDetalleUbicacionGU{
    width: 896px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#BoxBtnAsignarUbicacionGU{
    width: 200px;
    height: 48px;
}


#BoxLayoutConDetalleUbicacionGU{
    width: 896px;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 111px;
}

#BoxContenidoConDetalleUbicacionGU{
    width: 896px;
    height: 264px;
    display: flex;
    align-items: flex-start;
    gap: 35px;
}

#BoxTituloConDetalleUbicacionGU{
    width: 181px;
    height: 264px;
    display: flex;
    align-items: flex-start;
}

#TxtTituloConDetalleUbicacionGU{
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
}


#BoxContenidoConDetalleUbicacioGU{
    width: 680px;
    height: 264px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: var(--6, 48px);
}

#BoxContenidoNombreTipoConDetalleUbicacionGU{
    width: 680px;
    height: 56px;
    display: flex;
    align-items: flex-start;
    gap: var(--5, 40px);
}

#BoxNombreConDetalleUbicacionGU{
    display: flex;
    width: 320px;
    height: 56px;
    flex-direction: column;
    align-items: flex-start;
}
#BoxTipoConDetalleUbicacionGU{
    display: flex;
    width: 320px;
    height: 56px;
    flex-direction: column;
    align-items: flex-start;
}

#BoxContenidoPisoNumeracionConDetalleUbicacionGU{
    width: 680px;
    height: 56px;
    display: flex;
    align-items: flex-start;
    gap: var(--5, 40px);
}

#BoxPisoConDetalleUbicacionGU{
    display: flex;
    width: 320px;
    height: 56px;
    flex-direction: column;
    align-items: flex-start;
}
#BoxNumeracionConDetalleUbicacionGU{
    display: flex;
    width: 320px;
    height: 56px;
    flex-direction: column;
    align-items: flex-start;
}


#BoxAccionesConDetalleUbicacionGU{
    width: 896px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

#BoxBtnConDetalleUbicacionGU{
    width: 200px;
    height: 48px;
}


#BoxLayoutCambiarUbicacionGU{
    width: 896px;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 192px;
}


#BoxTituloContenidoCambiarUbicacionGU{
    width: 896px;
    height: 160px;
    display: flex;
    align-items: flex-start;
    gap: var(--5, 40px);
}

#BoxTituloCambiarUbicacionGU{
    width: 172px;
    height: 160px;
}

#TxtTituloCambiarUbicacionGU{
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    padding-bottom: 24px;
}

#BoxContenidoCambiarUbicacionGU{
    width: 684px;
    height: 160px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--6, 48px);
}

#BoxSelectCambiarUbicacionGU{
    width: 684px;
    height: 56px;
}

#FormControlAutocompleteUbicacionGU{
    width: 684px;
    height: 56px;
}

#BoxAccionesCambiarUbicacionGU{
    height: 48px;
    display: flex;
    width: 896px;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 16px;
}  
#BoxBtnCancelarCambiarUbicacionGU{
    width: 152px;
    height: 48px;
} 
#BoxBtnAgregarCambiarUbicacionGU{
    width: 152px;
    height: 48px;
}
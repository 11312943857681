#BoxPersonalizado{
    position: absolute;
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#CirculoDeDatos{
    position: absolute;
    top: 10px;
    right: 5px;
    width: 12px;
    height: 12px;
    color: #CC261A;
}

#ContadorEnCiruculo{
    position: absolute;
    text-align: center;
    top: 1px;
    right: 4.5px;
    color: #FFF;
    font-family: inherit;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; 
    letter-spacing: 0.5px;
}

#CampanaNotificacion{
    width: 20px;
    height: 23px;
    padding: 8px;
}
#BotonTipoCalendarioUno{
    width: 100%;
    height: 100%;
    /* padding: 10px 16px 10px 12px; */
    border-radius: 25px;
    background-color: #FFF;
    border: 1.5px solid #333;
    opacity: 0.8;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: inherit;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    text-transform: none !important;
}

#BotonTipoCalendarioUno:hover{
    border: 1.5px solid #1F7098;
    background: rgba(23, 86, 118, 0.08);
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
}

#BotonTipoCalendarioUno:active{
    border: 1.5px solid  #103D54;
    background:  rgba(23, 86, 118, 0.16);
}


#IconoInicio{
    width: 14px;
    height: 14px;
    margin-right: -3px;
}

#BoxSelectOpcionesVistasCalendario{
    height: 100%;
}


#BotonTipoCalendarioDos{
    width: 100%;
    height: 100%;
    padding: 4px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
    cursor: pointer;
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
    text-transform: none;
}

#BotonTipoCalendarioDos:hover{
    width: 100%;
    height: 100%;
    border: 1.5px solid #1F7098;
    padding: 4px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: #E9F1F5;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
    cursor: pointer;
    color: #175679;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
    text-transform: none;
}
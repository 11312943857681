#BoxCabeceraNuevaPatente{
    width: 992px;
    height: 48px;
    display: flex;
    padding: var(--2, 16px) var(--2, 16px) var(--none, 0px) var(--2, 16px);
    justify-content: flex-end;
    align-items: center;
    gap: var(--3, 24px);
    align-self: stretch;
}

#BoxContenidoOpcionNuevaPatente{
    width: 896px;
    height: 552px;
    display: flex;
    padding: var(--none, 0px) var(--8, 64px) var(--8, 64px) var(--8, 64px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--6, 48px);
}

#BoxAgregarNuevaPatente{
    width: 896px;
    height: 552px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--3, 24px);

}


#BoxTituloSubtituloOpcionNuevaPatente{
    width: 896px;
    height: 96px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

}


#BoxTituloOpcionNuevaPatente{
    width: 896px;
    display: flex;
    height: 40px;
    padding-bottom: 16px;
    align-items: flex-start;
    gap: 50px;
    align-self: stretch;
    border-bottom: 1px solid #B1B1B1;
}

#TxtTituloOpcionNuevaPatente{
    color: #333;
    font-family: inherit;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 125% */
}


#BoxSubTituloOpcionNuevaPatente{
    display: flex;
    width: 896px;
    height: 20px;
    padding: 10px 0px;
    align-items: center;
    align-self: stretch;
}

#TxtSubTituloOpcionNuevaPatente{
    color: #8A8A8A;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
}


#BoxFormularioOpcionNuevaPatente{
    width: 896px;
    height: 432px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;
}


#BoxContenidoFormularioNuevaPatente{
    width: 896px;
    height: 336px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--6, 20px);
}

#BoxInformacionPatenteNuevaPatente{
    width: 896px;
    height: 80px;
    display: flex;
    align-items: flex-start;
    gap: 36px;
}


#BoxTituloInformacionPatenteNuevaPatente{
    height: 24px;
    display: flex;
    width: 180px;
    padding-bottom: 32px;
    align-items: center;
}

#TxtTitulosFormulariosNuevaPatente{
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
}

#BoxTextFieldFormularioNuevaPatente{
    width: 680px;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--4, 32px);
}

#BoxSegundaInformacionPatenteNuevaPatente{
    width: 896px;
    height: 56px;
    display: flex;
    align-items: flex-start;
    gap: 36px;
}


#BoxTituloSegundaInformacionNuevaPatente{
    display: flex;
    width: 180px;
    height: 24px;
    padding-bottom: 32px;
    align-items: center;
}


#BoxTextFieldUsuarioFormularioNuevaPatente{
    display: flex;
    width: 680px;
    height: 56px;
    flex-direction: column;
    align-items: flex-start;

}

#BoxHandlerFormularioNuevaPatente{
    display: flex;
    height: 48px;
    width: 896px;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
}

#BoxAccionNuevaPatente{
    width: 152px;
    height: 48px;
}
#BoxHuellaGestionUsuario{
    width: 894px;
    height: 530px;
    display: flex;
    padding: var(--none, 0px) var(--8, 64px) 52px var(--none, 0px);
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: var(--6, 48px);
}


#BoxTextoTituloHuellaGU{
    display: flex;
    width: 894px;
    height: 40px;
    padding-bottom: 16px;
    align-items: flex-start;
    gap: 8px;
    border-bottom: 1px solid #B1B1B1;
    flex: 1 0 0;
}


#TxtTituloHuellaGU{
    color: #333;
    font-family: inherit;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
}

#BoxTablaHuellasGU{
    display: flex;
    width: 893px;
    height: 424px;
    flex-direction: column;
    align-items: flex-start;
    gap: 68px;
}

#DataGridHuellasGU{
    width: inherit;
    height: inherit;
}

#BoxLayoutAgregarHuellaGU{
    width: 896px;
    height: 416px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 96px;
}

#BoxImagenContenidoAgregarHuellaGU{
    width: 896px;
    height: 272px;
    display: flex;
    align-items: flex-start;
    gap: var(--5, 40px);
}

#BoxImgAgregarHuellaGU{
    width: 272px;
    height: 272px;
}

#ImgAgregarHuellaGU{
    width: 272px;
    height: 272px;
    opacity: 0.8;
}


#BoxContenidoAgregarHuellaGU{
    width: 584px;
    height: 152px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: var(--6, 48px);
}


#BoxDedoLectorAgregarHuellaGU{
    width: 584px;
    height: 56px;
    display: flex;
    align-items: flex-start;
    gap: var(--5, 40px);
}

#BoxSelectDedoAgregarHuellGU{
    display: flex;
    width: 240px;
    height: 56px;
    flex-direction: column;
    align-items: flex-start;
}

#BoxSelectLectorAgregarHuellGU{
    display: flex;
    width: 304px;
    height: 56px;
    flex-direction: column;
    align-items: flex-start;
}


#BoxAccionesAgregarHuellaGU{
    display: flex;
    width: 896px;
    height: 48px;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 16px;
}

#BoxBtnCancelarAgregarHuellaGU{
    width: 152px;
    height: 48px;
}

#BoxBtnAgregarAgregarHuellaGU{
    width: 200px;
    height: 48px;
}

#iconoEnTablaHuellas{
    width: 40px;
    height: 40px;
}
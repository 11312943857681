#BoxPatentesGestionUsuario{
    width: 894px;
    height: 530px;
    display: flex;
    padding: var(--none, 0px) var(--8, 64px) 52px var(--none, 0px);
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: var(--6, 48px);
}

#BoxTextoTituloPatenteGU{
    display: flex;
    width: 894px;
    height: 40px;
    padding-bottom: 16px;
    align-items: flex-start;
    gap: 8px;
    border-bottom: 1px solid #B1B1B1;
    flex: 1 0 0;
}

#TxtTituloPatenteGU{
    color: #333;
    font-family: inherit;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
}

#BoxTablaPatenteGU{
    display: flex;
    width: 893px;
    height: 424px;
    flex-direction: column;
    align-items: flex-start;
    gap: 68px;
}

#DataGridPatentesGU{
    width: inherit;
    height: inherit;
}


#BoxLayoutAgregarPatenteGU{
    width: 896px;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 296px;
}

#BoxTituloTxtAgregarPatenteGU{
    width: 896px;
    height: 56px;
    display: flex;
    align-items: flex-start;
    gap: var(--5, 40px);
}

#BoxTituloAgregarPatenteGU{
    width: 172px;
    height: 56px;
}

#TxtTituloAgregarPatenteGU{
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
    padding-bottom: 32px;
}

#BoxTxtAgregarPatenteGU{
    display: flex;
    width: 684px;
    height: 56px;
    flex-direction: column;
    align-items: flex-start;
}

#BoxAccionesAgregarPatenteGU{
    display: flex;
    width: 896px;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 16px;
}

#BoxBtnCancelarAgregarPatenteGU{
    width: 152px;
    height: 48px;
}

#BoxBtnAgregarAgregarPatenteGU{
    width: 152px;
    height: 48px;
}

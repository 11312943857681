#CabeceraNuevoUsuario{
    display: flex;
    padding: var(--2, 16px) var(--2, 16px) var(--none, 0px) var(--2, 16px);
    justify-content: flex-end;
    align-items: center;
    gap: var(--3, 24px);
    align-self: stretch;
}


#ContenidoModalNuevoUsuario{
    width: 1016px;
    height: 486px;
    display: flex;
    padding: var(--none, 0px) var(--8, 64px) var(--8, 64px) var(--8, 64px);
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

#BoxTitutloModalNuevoUsuario{
    display: flex;
    width: 1016px;
    padding-bottom: 16px;
    align-items: flex-start;
    gap: 8px;
    border-bottom: 1px solid #B1B1B1;
}

#TituloNuevoUsuario{
    display: flex;
    height: 48px;
    justify-content: center;
    align-items: center;
    color: #333;
    font-family: inherit;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
}

#BoxSubtituloModalNuevoUsuario{
    width: 1016px;
    height: 24px;
    display: flex;
    padding: 8px 0px;
    padding-bottom: 30px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}


#SubtituloNuevoUsuario{
    color: #8A8A8A;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
}

#BoxFormNuevoUsuario{
    width: 1016px;
    height: 290px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;  
}

#BoxInformacionPersonal{
    display: flex;
    align-items: flex-start;
    gap: var(--5, 40px);
    width: 100%;
    height: 144px;
}

#TituloInformacionPersonal{
    display: flex;
    width: 200px;
    padding-bottom: 24px;
    align-items: center;
}


#TxtTituloInformacionPersonal{
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
}

#BoxFormularioInformacionPersonal{
    width: 776px;
    height: 144px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--5, 40px);
}

#BoxNombreApellido{
    width: 776px;
    height: 52px;
    display: flex;
    align-items: flex-start;
    gap: var(--5, 40px);
}
#BoxRutTipo{
    width: 776px;
    height: 52px;
    display: flex;
    align-items: flex-start;
    gap: var(--5, 40px);
}

#selectTipoUsuario{
    width: 368px;
    height: 48px;
    padding: 0px;
}
#FormControlTipoUsuarioNuevo{
    width: 100%;
    height: 100%;
}


#TituloInformacionContacto{
    display: flex;
    width: 200px;
    height: 52px;
    align-items: center;
}

#TxtTituloInformacionContacto{
    color: #175676;
    padding-bottom: 24px;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
}

#BoxInformacionContacto{
    width: 100%;
    height: 52px;
    display: flex;
    align-items: flex-start;
    gap: var(--5, 40px);
}

#BoxFormularioInformacionContacto{
    height: 52px;
    width: 776px;
    display: flex;
    align-items: flex-start;
    gap: var(--5, 40px);
}

#BotoncesAccionModalNuevoUsuario{
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

#BoxBotones{
    width: 368px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#BoxBotonNuevoUsuario{
    width: 176px !important;
    height: 48px;
}


#BoxItemTipoUsuario{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 15px;
}


#IconoLimpiarTextFieldNuevoUsuario{
    width: 20px;
    height: 20px;
    padding: 4px;
}



#BoxIdentificadorNuevoUsuario{
    width: 368px;
    height: 48px;
    display: flex;
    align-items: center;
    gap: 20px;
}

#BoxIdentificadorRutNuevoUsuario{
    width: 174px;
    height: 48px;
}

#BoxIdentificadorPasaporteNuevoUsuario{
    width: 174px;
    height: 48px;

}
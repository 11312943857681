#BoxDetalleRegistro{
    width: 200px;
    height: 260px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 30px;
    gap: 4px;
    border-radius: 16px;
}

#CabeceraBoxDetalleRegistro{
    width: 100%;
    height: 30px;
    text-align: center;
    border-bottom: 1px solid #175676;
}

#ContenidoBoxDetalleRegistro{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-content: flex-start;
    gap: 14px;
}

#BoxNombreDestinoDetalleRegistro{
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#TituloNombreDestinoDetalleRegistro{
    width: 100%;
    text-align: center;
}

#NombreDestinoDetalleRegistro{
    width: 100%;
    text-align: center;
}

#BoxUbicacionDetalleRegistro{
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
#TituloUbicacionDetalleRegistro{
    width: 100%;
    text-align: center;
}
#UbicacionDetalleRegistro{
    width: 100%;
    text-align: center;
}


#BoxComentarioDetalleRegistro{
    width: 100%;
    height: 70px;
    gap: 4px;
    text-align: center;
}

#BoxTituloComentarioDetalleRegistro{
    width: 100%;
    height: 24px;
    text-align: center;
}

#ComentarioDetalleRegistro{
    width: 100%;
    height: 50px;
}


#TxtTituloNombreDestinoDetalleRegistro{
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; 
    letter-spacing: 0.15px;
}

#TxtNombreDestinoDetalleRegistro{
    color: #626262;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    letter-spacing: 0.25px;
}


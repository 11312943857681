#idSupersiorBoxUbicaciones{
    width: 896;
    height: 48px;
    display: flex;
    border-radius: 16px;
    justify-content: flex-end;
}

#inferiorBoxModalUbicaciones{
    display: flex;
    padding: var(--none, 0px) var(--8, 64px) var(--8, 64px) var(--8, 64px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--5, 40px);
}

#BoxXGestionUbicaciones{
    display: flex;
    padding: var(--2, 16px) var(--2, 16px) var(--none, 0px) var(--2, 16px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--6, 48px);
}

#tituloBoxGestionUbicacion{
    display: flex;
    flex-direction: column;
    height: 56px;
    padding-bottom: 16px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

#tituloBoxGestionUsuarios{
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}


#typographyTituloGestionUbicaciones{
    color: #333;
    /* HL-Roboto */
    font-family: inherit;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 125% */
}

#typographySubTituloGestionUbicaciones{
    color: #8A8A8A;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
}

#cuerpoBoxGestionUbicaciones{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 80px;
    margin-top: 33px;
}
#FormularioBoxGestionUbicaciones{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--8, 64px);
}
#BoxGestionUbicaciones1{
    display: flex;
    align-items: flex-start;
    gap: 36px;
    width: 896px;
}
#BoxGestionUbicaciones2{
    display: flex;
    align-items: flex-start;
    gap: 36px;
}

#TypographyGestionUbicaciones1{
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
}

#TypographyGestionUbicaciones2{
    color: var(--Controlpass-Primary-blue-500, #175676);
    /* TM-Roboto */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
}

#BoxGestionUbicaciones3{
    display: flex;
    width: 100%;
    height: 56px;
    flex-direction: column;
    align-items: flex-start;
}

#BoxGestionUbicaciones4{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--6, 48px);
    width: 100%;
}

#BoxGestionUbicaciones5{
    display: flex;
    align-items: flex-start;
    gap: var(--5, 40px);
    width: 100%;
}

#BoxGestionUbicaciones6{
    display: flex;
    width: 186px;
    padding-bottom: 32px;
    align-items: center;
}
#BoxGestionUbicaciones7{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    height: 40px;
}

#BoxGestionUbicaciones8{
    display: flex;
    padding:  4px 0px 4px 0px;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    width: 304px;
    height: 48px;
}

#BoxGestionUbicaciones9{
    display: flex;
    width: 896px;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
}

#BoxGestionUbicaciones10{
    display: flex;
    width: 152px;
    height: 48px;
    padding: 12px 0px 0px 0px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
}

#tituloBoxVerInformacionUsuario{
    display: flex;
    flex-direction: column;
    height: 56px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

#tituloModalInformacionUsuario{
    display: flex;
    padding: var(--none, 0px) var(--8, 64px) var(--8, 0px) var(--8, 64px);
    flex-direction: column;
    align-items: flex-start;
}

#BoxVerUsuarios00{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 64px;
    margin-left: 64px;
    width: 896px;
    height: 432px;
}

#BoxVerUsuarios01{
    display: flex;
    width: 896px;
    height: 160px;
    align-items: center;
}

#BoxVerUsuarios02{
    display: flex;
    flex-direction: column;
    height: 24px;
    width: 180px;
    margin-bottom: 136px;
}

#BoxVerUsuarios03{
    display: flex;
    flex-direction: column;
    height: 160px;
    width: 680px;
    margin-left: 36px;
}

#BoxVerUsuarios04{
    width: 680px;
    height: 56px;
    margin-bottom: 48px;
    display: flex;
    gap: 40px;
    
}

#BoxVerUsuarios05{
    width: 680px;
    height: 56px;
    display: flex;
    gap: 40px;
}

#BoxVerUsuarios06{
    display: flex;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
}

#BoxVerUsuarios07{
    display: flex;
    width: 896px;
    height: 56px;
    margin-top: 64px;
}

#BoxVerUsuarios08{
    display: flex;
    gap: 40px;
    width: 680px;
    height: 56px;
    margin-left: 36px;
}

#BoxVerUsuarios09{
    display: flex;
    width: 896px;
    height: 48px;
    justify-content: flex-end;
    margin-top: 104px;
}

#BoxVerUsuarios10{
    width: 152px;
    height: 48px;
}   

#textFieldVerUsuario01{
    width: 320px;
    height: 49px;
}


/* Estilos para el botón de ver mas */

/* Color de fondo del rectángulo interno */
.icono-ver-mas .fondo-icono-ver-mas {
    fill: white;
}

/* Cambia el color de fondo al pasar el cursor */
.icono-ver-mas:hover .fondo-icono-ver-mas {
    fill: #E8EEF1;
}

/* Cambia el color del botón cuando se hace clic (mientras está activo) */
.icono-ver-mas:active .fondo-icono-ver-mas {
    fill: #1F7098;
}

/* Color del trazo del icono */
.icono-ver-mas .trazo-icono-ver-mas {
    stroke: #626262;
    stroke-width: 2.5;
    stroke-linecap: round;
    stroke-linejoin: round;
}

/* Cambia el color del trazo al pasar el cursor */
.icono-ver-mas:hover .trazo-icono-ver-mas {
    stroke: #1F7098;
}

/* Cambia el color del trazo cuando se hace clic (mientras está activo) */
.icono-ver-mas:active .trazo-icono-ver-mas {
    stroke: white;
}
#BoxLayoutGestionAccesos{
    width: inherit;
    height: inherit;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;
}

#BoxContenedorTablaAccesos{
    width: inherit;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;
}

#CabeceraGestionAccesos{
    display: flex;
    padding: var(--2, 16px) var(--2, 16px) var(--none, 0px) var(--2, 16px);
    justify-content: flex-end;
    align-items: center;
    gap: var(--3, 24px);
    align-self: stretch;
}

#BoxTituloSubtituloOpcionGestionAcceso{
    width: 896px;
    height: 96px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

#BoxTituloOpcionGestionAcceso{
    width: 896px;
    display: flex;
    height: 40px;
    padding-bottom: 16px;
    align-items: flex-start;
    gap: 50px;
    align-self: stretch;
    border-bottom: 1px solid #B1B1B1;
}


#TxtTituloOpcionGestionAcceso{
    color: #333;
    font-family: inherit;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 125% */
}

#BoxSubTituloOpcionGestionAcceso{
    display: flex;
    width: 896px;
    height: 20px;
    padding: 10px 0px;
    align-items: center;
    align-self: stretch;
}

#TxtSubTituloOpcionGestionAcceso{
    color: #8A8A8A;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
}




#CuerpoModalAgregarAcceso{
    width: 896px;
    height: 450px;
    display: flex;
    padding: var(--none, 0px) var(--8, 64px) var(--8, 64px) var(--8, 64px);
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
}

#BoxFormularioOpcionGestionAcceso{
    width: 896px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 100px;
}

#BoxtituloNuevoAcceso{
    display: flex;
    height: 56px;
    padding-bottom: 16px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-bottom: 1px solid #B1B1B1;
}

#TituloNuevoAcceso{
    display: flex;
    height: 48px;
    justify-content: center;
    align-items: center;
    color: #333;
    font-family: inherit;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
}

#BoxSubtituloModalAgregarAcceso{
    display: flex;
    width: 896px;
    padding: 10px, 0px;
    align-items: center;
    align-self: stretch;
}

#SubtituloNuevoAcceso{
    color: #8A8A8A;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 0px;
    letter-spacing: 0.5px;
}

#FormularioNuevoAcceso{
    width: 896px;
    height: 408px;
}
#tituloModalAgregarAcceso{
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
}
#BoxBotonesNuevaPatenteModal{
    margin-top: 100px;
    gap: 16px;
    width: 896px;
    height: 56px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
}


#BoxSegundaInformacionOpcionGestionAcceso{
    width: 896px;
    height: 56px;
    display: flex;
    align-items: flex-start;
    gap: 36px;
}


#BoxHandlerFormularioOpcionGestionAcceso{
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
}

#BoxInformacionOpcionGestionAcceso{
    width: 896px;
    height: 56px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 36px;
}

#BoxTituloInformacionOpcionGestionAcceso{
    height: 56px;
    display: flex;
    width: 180px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}


#TxtTitutlosFormulariosOpcionGestionAcceso{
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
}



#BoxContenidoFormularioOpcionGestionAcceso{
    width: 100%;
    height: 170px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 56px;
}

#BoxOpcionesFormularioGestionAcceso{
    width: 680px;
    height: 56px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--4, 2px);
}
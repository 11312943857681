#BoxContenidoDetalleEventoFechaDuracion{
    width: 332px;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 26px;
}

#BoxContenedorFechaFormatoCalendario{
    width: 54px;
    height: 75px;
}



#BoxCabeceraCalendarioDetalleEventoView{
    width: 54px;
    height: 14px;
    border: 1px solid #CC261A;
    border-radius: 8px 8px 0px 0px;

}


#BoxCuerpoCalendarioDetalleEventoView{
    width: 54px;
    height: 37px;
    border: 1px solid #CC261A;
    border-top: none;
    border-radius: 0px 0px 8px 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#TxtCalendarioFechaInicioEventoView{
    color: #CC261A;
    font-family: inherit;
    font-size: 28px;
    font-style: normal;
    line-height: normal;
    font-weight: 500;
}


#BoxFooterCalendarioDetalleEventoView{
    width: 54px;
    height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#TxtMesCortoCalendarioFechaInicioEventoView{
    color: #CC261A;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
    font-weight: 500;
}



#BoxContenedorInformacionEvento{
    width: 200px;
    max-width: 200px;
    height: 80px;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}


#BoxNombreEventoAgendadoView{
    width: 100%;
    height: 20px;
    text-align: left;
}
#TxtNombreEventoAgendadoView{
    color: #175676;
    font-family: inherit;
    font-size: 20px;
    font-style: normal;
    line-height: normal;
    font-weight: 500;
}


#BoxContenedoHorasInicioTerminoEventoView{
    width: 100%;
}

#BoxContenedorTxtHoraInicioFinEventoView{
    width: 100%;
    height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

#TxtHoraInicioFinEventoView{
    color: #333;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
    font-weight: 500;
}


#BoxContenedorFechaTerminoTipoUnoEventoView{
    width: 100%;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

#TxtFechaTerminoFinEventoView{
    color: #626262;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    font-weight: 400;
}



#BoxContenedorUbicacionEventoView{
    width: 100%;
    height: 20px;
}

#TxtNombreUbicacionEventoAgendadoView{
    color: #626262;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    font-weight: 400;
}



/*Evento corto tipo dos css*/

#BoxContenidoDetalleEventoFechaDuracionTipo2{
    width: 304px;
    height: 55px;
    min-height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 4px;
    gap: 40px;
    border-left: 10px solid rgba(1, 1, 1, 0.15);
    box-shadow: 1px 1px 1px 1px rgba(1, 1, 1, 0.15), 1px 1px 2px 0px rgba(0, 0, 0, 0.30);
}

#BoxContenidoDetalleEventoFechaDuracionTipo2:hover{
    background: rgba(76, 76, 76, 0.08);
}


#BoxContenedorInformacionEventoTipo2{
    width: 200px;
    max-width: 200px;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#BoxNombreEventoAgendadoViewTipo2{
    width: 100%;
    height: 15px;
    text-align: left;
}

#TxtNombreEventoAgendadoViewTipo2{
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
    font-weight: 450;
    padding-left: 5px;
}

#BoxContenedoHorasInicioTerminoEventoViewTipo2{
    width: 100%;
}

#BoxContenedorTxtHoraInicioFinEventoViewTipo2{
    width: 100%;
    height: 18px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
}

#TxtHoraInicioFinEventoViewTipo2{
    color: #333;
    font-family: inherit;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    font-weight: 450;
    padding-left: 5px;
}

#BoxContenedorFechaTerminoTipoUnoEventoViewTipo2{
    width: 100%;
    height: 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

#TxtFechaTerminoFinEventoViewTipo2{
    color: #626262;
    font-family: inherit;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    font-weight: 400;
    padding-left: 5px;
}

#BoxOpcionEditarUsuario{
    width: inherit;
    height: inherit;
}
#BoxtTituloEditarGU{
    width: 896px;
    height: 48px;
    margin-bottom: 40px;
}

#TxtTituloEditarGU{
    color: #333;
    font-family: inherit;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 125% */
}

#BoxContenidoEditarGU{
    width: 896px;
    height: 408px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 80px;
}

#BoxDataPersonalContactoGU{
    width: 896px;
    height: 280px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--8, 64px);
}

#BoxAccionesPersonalContactoGU{
    width: 896px;
    height: 48px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 16px;
}

#BoxIdentificadoresInformacionUsuario{
    width: 320px;
    height: 54px;
    display: flex;
    align-items: center;
    gap: 20px;
}

#BoxIdentificadorRutInformacionUsuario{
    width: 150px;
    height: 54px;
}

#BoxIdentificadorPasaporteInformacionUsuario{
    width: 150px;
    height: 54px;
}
#IconoEnTFUno{
    width: 20px;
    height: 20px;
    padding: 8px;
}
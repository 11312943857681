#BoxRadioRecurrencia{
    width: 30px !important;
    border-radius: 25px;
    height: 30px !important;
    margin-right: 12px;
}

#TextoDiaRecurrencia{
    color: #103D54;
    font-family: inherit;
    font-size: 12px;
    font-style: normal;
    font-weight: 550;
    line-height: 16px;
    letter-spacing: 0.5px;
}

#ContenedorTexto{
    background: transparent;
}
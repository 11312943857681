#RecuperarLayout{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 32px;
}

#CardRecuperar{
    width: 624px;
    height: 920px;
    border-radius: 32px;
}

#CardContenidoRecuperar{
    width: 408px;
    height: 488px;
    padding-top: 212px;
    padding-bottom: 220px;
    padding-left: 108px;
    padding-right: 108px;
}

#BoxImagen{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#imagen{
    width: 256px;
    height: 152px;
}

#BoxTitulo{
    width: 100%;
    text-align: center;
    padding-top: 48px;
}

#TextoTitulo{
    color: #175676;
    text-align: center;
    font-family: inherit;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
}

#ComentarioRecuperacion{
    color: #292929;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    padding-top: 16px;
}

#BoxConstrasenas{
    width: 100%;
    padding-top: 16px;
}

#BoxConfirmacion{
    padding-top: 24px;
}

#BoxBoton{
    width: 100%;
    padding-top: 32px;
}

#ButtonCambiar{
    border-radius: 100px;
    background: #175676;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
}

#TextoBoton{
    text-transform: none;
    color: #FFF;
    text-align: center;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
}

#errorSpan{
    color: #EF4537;
    font-family: inherit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px
}
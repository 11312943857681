#BoxAgregarNuevaUbicacion{
    width: 896px;
    height: 552px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--3, 24px);
}

#BoxTituloSubtituloOpcionNuevaUbicacion{
    width: 896px;
    height: 96px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

#BoxTituloOpcionNuevaUbicacion{
    width: 896px;
    display: flex;
    height: 40px;
    padding-bottom: 16px;
    align-items: flex-start;
    gap: 50px;
    align-self: stretch;
    border-bottom: 1px solid #333;
    flex-direction: row;
}


#TxtTituloOpcionNuevaUbicacion{
    color: #333;
    font-family: inherit;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 125% */
}

#TxtSubTituloOpcionNuevaUbicacion{
    color: #8A8A8A;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
}


#BoxFormularioOpcionNuevaUbicacion{
    width: 896px;
    height: 432px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;
}

#BoxContenidoFormularioOpcionNuevaUbicacion{
    width: 896px;
    height: 336px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--6, 48px);
}


#BoxSegundaInformacionOpcionNuevaUbicacion{
    width: 896px;
    height: 56px;
    display: flex;
    align-items: flex-start;
    gap: 36px;
}

#BoxTituloSegundaInformacionOpcionNuevaUbicacion{
    display: flex;
    width: 180px;
    height: 24px;
    padding-bottom: 32px;
    align-items: center;
}


#TxtTitutlosFormulariosOpcionNuevaUbicacion{
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
}



#BoxSegundasOpcionesFormularioNuevaUbicacion{
    display: flex;
    width: 680px;
    height: 59px;
    flex-direction: column;
    align-items: flex-start;
}

#BoxFormularioNombreTipoNuevaUbicacion{
    display: flex;
    width: 680px;
    height: 250px;
    gap: 30px;
    flex-direction: column;
    align-items: flex-start;
}


#BoxInformacionSobreUbicacion{
    display: flex;
    width: 680px;
    height: 59px;
    flex-direction: row;
    align-items: flex-start;
    gap: 40px;
}
#BoxNombreNuevaUbicacion{
    width: 320px;
}
#BoxTipoNuevaUbicacion{
    width: 320px;
}

#BoxHandlerFormularioOpcionNuevaUbicacion{
    display: flex;
    height: 48px;
    width: 896px;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
}


#BoxTamanoBotonHandler{
    width: 152px;
    height: 48px;
}


#BoxLayoutNoExisteEnListaNegraGU{
    width: 896px;
    height: 448px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

#BoxMensajeNoExisteEnListaNegraGU{
    width: 896px;
    height: 72px;
    text-align: center;
}

#TxtMensajeNoExisteEnListaNegraGU{
    color: #626262;
    text-align: center;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
}

#BoxBotonRapidoAgregarListaNegraGU{
    width: 896px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
#BoxBotonAgregarListaNegraGU{
    width: 200px;
    height: 48px;
}
#IconoRespuestaNuevoEvento{
    width: 88px;
    height: 88px;
}

#TituloRespuestaNE{
    color: #333;
    font-family: inherit;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
}


#TextoRespuestaNE{
    padding-top: 16px;
    color: #626262;
    text-align: center;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
}

#BoxAceptarRespuesta{
    width: 320px;
    height: 48px;
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 52px !important;
}

#ContentRespuestaNuevoEvento{
    width: 324px;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center !important;
    gap: 18px;
}

#TipoRespuesta{
    width: 324px;
    text-align: center;
}

#BoxBotonRespuestaCreacion{
    width: 152px;
    height: 48px;
}

#IconoRespuestaNuevoEvento{
    width: 324px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center !important;
}


#BotonAceptarNE:hover{
    width: 152px;
    height: 48px;
    border-radius: 100px;
    background: #175676;
    color: #FFF;
    text-align: center;
    font-family: inherit;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
}

#BotonAceptarNE{
    color: #175676;
    text-align: center;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    text-transform: none;
}

#BotonAceptarNE{
    width: 152px;
    height: 48px;
    border-radius: 100px;
    background: #FFFFFF;
    border: 1.5px solid #175676;
}


#BoxAceptarRespuestaNEN{
    width: 320px;
    height: 48px;
    padding-top: 52px !important;
}

#BoxAceptarRespuestaNE{
    width: 320px;
    height: 48px;
    text-align: center !important;
    padding-top: 52px !important;
}



#BoxIconoCierreNuevaZona{
    width: 1144px;
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;    
    
}

#BoxIconoNuevaZona{
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 14px;
}

/*body modal de las zonas*/

#BoxContenidoOpcionesZona{
    width: 1016px;
    height: 480px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 64px 64px 64px;
}

#BoxBodyContenidoNuevaZona{
    width: 1016px;
    height: 480px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 100px;
}

#BoxContenidoFormularioNuevaZona{
    width: 1016px;
    height: 408px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
}

#BoxTituloSubTituloNuevaZona{
    width: 1016px;
    height: 96px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#BoxTituloNuevaZona{
    width: 1016px;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #B1B1B1;
    padding-bottom: 16px;
}

#TxtTituloNuevaZona{
    color: #333;
    font-family: inherit;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 125% */
}

#BoxSubTituloNuevaZona{
    width: 1016px;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

#TxtSubtituloNuevaZona{
    color: #8A8A8A;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
}

#BoxFormularioNuevaZona{
    width: 1016px;
    height: 207px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
}

#BoxFormularioOpcionNombreNuevaZona{
    width: 1016px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 36px;
}

#BoxTituloOpcionNombreFormularioNuevaZona{
    width: 200px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

#BoxTxtOpcionNombreFormularioNuevaZona{
    width: 780px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

#BoxFormularioOpcionAPBNuevaZona{
    width: 1016px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 36px;
}

#BoxTituloOpcionAPBFormularioNuevaZona{
    width: 200px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

#BoxTxtOpcionNombreFormularioNuevaZona{
    width: 780px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

#BoxAccionesFormularioNuevaZona{
    width: 1016px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
}


#BoxBtnAccionNuevaZona{
    width: 170px;
    height: 48px;
}

#TxtTituloOpcionFormularioNuevo{
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
}
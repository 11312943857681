#BoxDialogConfirmacion{
    width: 324px;
    height: 316px;
    display: inline-flex;
    padding: 80px 40px 40px 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;
}

#BoxDataConfirmacion{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--4, 32px);
}

#BoxIconoDialogConfirmacion{
    width: 88px;
    height: 88px;
    display: flex;
    padding: 4px;
    align-items: flex-start;
    gap: 10px;
}

#BoxTituloMensajeConfirmacion{
    width: 324px;
    height: 92px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--2, 16px);
}

#BoxTituloDialogConfirmacion{
    width: 300px; /* Cambiado de 261px, 23/05/2024 CM  */
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#txtTituloDialogConfirmacion{
    color: #333;
    font-family: inherit;
    font-size: 27px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
}

#BoxMensajeDialogConfirmacion{
    display: flex;
    width: 324px;
    height: 40px;
    justify-content: center;
    align-items: center;
}

#txtMensajeDialogConfirmacion{
    color: #626262;
    text-align: center;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
}

#BoxAccionesDialogConfirmacion{
    width: 320px;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;
}
#BoxOpcionesDialogConfirmacion{
    width: 320px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
}

#BoxBotonDialogConfirmacion{
    width: 152px;
    height: 48px;
}

#BoxContenedorConfirmDos{
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: var(--2, 16px);
    background:#FFF;
}

#BoxIMGTituloMensaje{
    width: 324px;
    height: 324px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--2, 16px);
}

#BoxImagenConfigmDos{
    height: 208px;
    align-self: stretch;
}
#IMGConfirmDos{
    width: 100%;
    height: 100%;
}

#BoxTituloMensajeConfirmDos{
    width: 324px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--2, 16px);
}

#BoxTituloConfirmDos{
    width: 324px;
    height: 36px;
    display: flex;
    min-width: 220px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
}

#TituloConfirmDos{
    color: #333;
    text-align: center;
    font-family: inherit;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 128.571% */
}

#MensajeConfirmDos{
    color: #626262;
    text-align: center;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
}

#BoxMensajeConfirmDos{
    width: 324px;
    height: 48px;
    display: flex;
    min-width: 220px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
}

#BoxBotonesConfirmDos{
    width: 324px;
    height: 48px;
}

#BoxBotonesAccionesConfirmDos{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 16px;
}



#BoxBotonConfirmDos{
    width: 152px;
    height: 48px;
}


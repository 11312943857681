#ComponenteGraficos{
    display: flex;
    flex-direction: column;
    width: inherit;
    height: 100%;
    align-items: flex-start;
    gap: 28px;
}

#BoxInternoDosGraficos{
    width: inherit;
    height: inherit;
    display: flex;
    align-items: flex-start;
    gap: 46px;
}

#BoxIzquierdaGraficos{
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    width: 976px;
    /* height: 572px; */
    height: 582px;
    border-radius: 16px;
    background: var(--Controlpass-Basic-white-500, #FFF);

    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.30);

}
#BoxDerechaGraficos{
    display: flex;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 38px;
    width: 432px;
    /* height: 572px; */
    height: 582px;

    border-radius: 16px;
    background: #FFF;
    /* Effect 03 */
    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.30);
}

#TituloGraficoIzquierda{
    color: #626262;
    /* TM-Roboto */
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
/* Ambos titulos */
}


#BoxTituloGraficoIzquierda{
    display: flex;
    /* width: 432px; */
    width: 928px;
    align-items: center;
    justify-content: space-between;
    gap: 64px;
}

#BoxTituloGraficoDerecha{
    display: flex;
    width: 416px;
    max-height: 24px !important;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

#BoxGraficoDerecha{
    display: flex;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 38px;
}

#BoxContenedorGrafico{
    display: flex;
    padding: var(--1, 8px);
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
}

#idBoxGraficoIzquierdo{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 55px;
    width: 927px;
    /* height: 456px; */
    height: 510px;
    padding-bottom: 0px;
}

#BoxLeyenda{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

#idholdingLeyenda{
    width:100%;
    max-height: 48px;
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    gap: 40px;
    overflow-y: hidden;
    overflow-x: hidden;
}

#idholdingLeyenda::-webkit-scrollbar{
    width: 7.024px !important;
    background: transparent !important;
} 

#idholdingLeyenda::-webkit-scrollbar-track{
    background: transparent !important;
}

#idholdingLeyenda::-webkit-scrollbar-thumb{
    background: #C9C9C9;
    border-radius: 100px;
}

#boxLeyendaDerecha{
    height: 228px;
    width: 416px;
    flex-wrap: nowrap;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
}

#boxLeyendaDerecha::-webkit-scrollbar{
    width: 7.024px !important;
    background: transparent !important;
} 

#boxLeyendaDerecha::-webkit-scrollbar-track{
    background: transparent !important;
}

#boxLeyendaDerecha::-webkit-scrollbar-thumb{
    background: #C9C9C9;
    border-radius: 100px;
}

#componenteLeyenda{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 9px;
    margin-left: 3px;
}

#LetraLeyendaDerecha{
    color: #626262;
    /* BM-Roboto */
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
}

#CentrarLeyendaDerecha{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 36px;
}

#boxlineasGraficoCircular{
    display: flex;
    flex-direction: row;
    margin: 0px 9px 8px 3px;
    max-height: 4px !important;

}
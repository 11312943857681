#LeftMenu{
    position: absolute !important;
    top: 0;
    margin-top: 16px;
    margin-left: 16px;
    left: 0;
    bottom: 0;
    margin-bottom: 16px;
    border-radius: 24px !important;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
    background-color: #FFFFFF;
}
#ImgLogoMenu{
    width: 100px;
    height: 50px;
}

#ContenedorLogoIconoCierre{
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    height: 60px !important;
    padding-left: 72px !important;
    padding-right: 24px !important;
    padding-bottom: 24px !important;
    padding-top: 24px !important;
}

#ContenedorLogoIconoAbierto{
    display: flex !important;
    flex-direction: column !important;
    align-items: center;
    height: 60px !important;
    padding-top: 24px !important;
    padding-bottom: 68px !important;
}

#LogoMenuAbrir{
    padding-bottom: 23px;
}

#LogoMenu{
    display: flex !important;
    align-items: center !important;
    padding-right: 16px;
}

#BoxAbrir{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

#BoxCerrar{
    display: flex !important;
    align-items: center !important;
}

#BoxSaludoNombre{
    width: inherit;
    height: 60px;
}

#Contendor{
    width: inherit;
    height: inherit;
    text-align: center;
}



#MensajeSaludo{
    color: #333;
    font-family: inherit;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    padding-bottom: 8px !important;
}


#MensajeNombreUsuario{
    color: #626262;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
}


#ListaMenu{
    padding-top: 33px;
    max-height: 550px;
}


#BoxCerrarSesion{
    width: 100%;
    height: 58px !important;
    position: inherit;
    border-radius: 0 0 24px 24px;
    bottom: 0;
}

#botonCierreSesion{
    padding: 0px;
    width: 248px !important;
    height: 48px !important;
}

#TextoBotonCierreSesion{
    text-transform: none !important;
    color: #626262;
    text-align: center;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
}


#BoxCerrarSesionSinTexto{
    height: 48px !important;
    padding-bottom: 0px;
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    bottom: 0px;
}

#botonCierreSesionSinTexto{
    padding: 0px;
    height: 48px !important;
}


#CerrarMenuIcon{
    width: 8px;
    height: 16px;
    padding: 12px 16px;
}

#AbriMenuIcon{
    width: 8px;
    height: 16px;
    padding: 12px 16px; 
}
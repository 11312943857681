#BoxCabeceraNuevoActuador{
    width: 992px;
    height: 48px;
    display: flex;
    padding: var(--2, 16px) var(--2, 16px) var(--none, 0px) var(--2, 16px);
    justify-content: flex-end;
    align-items: center;
    gap: var(--3, 24px);
    align-self: stretch;
}
#BoxContenidoOpcionGestionActuador{
    width: 896px;
    height: 552px;
    display: flex;
    padding: var(--none, 0px) var(--8, 64px) var(--8, 64px) var(--8, 64px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--6, 48px);
}

#BoxAgregarNuevoActuador{
    width: 896px;
    height: 552px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--3, 24px);
}

#BoxTituloSubtituloOpcionGestionActuador{
    width: 896px;
    height: 96px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

#BoxTituloOpcionGestionActuador{
    width: 896px;
    display: flex;
    height: 40px;
    padding-bottom: 16px;
    align-items: flex-start;
    gap: 50px;
    align-self: stretch;
    border-bottom: 1px solid #B1B1B1;
}

#TxtTituloOpcionGestionActuador{
    color: #333;
    font-family: inherit;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 125% */
}

#BoxSubTituloOpcionGestionActuador{
    display: flex;
    width: 896px;
    height: 20px;
    padding: 10px 0px;
    align-items: center;
    align-self: stretch;
}

#TxtSubTituloOpcionGestionActuador{
    color: #8A8A8A;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
}

#BoxFormularioOpcionGestionActuador{
    width: 896px;
    height: 432px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;
}

#BoxContenidoFormularioOpcionGestionActuador{
    width: 896px;
    height: 336px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--6, 48px);
}

#BoxInformacionOpcionGestionActuador{
    width: 896px;
    height: 232px;
    display: flex;
    align-items: flex-start;
    gap: 36px;
}
#BoxTituloInformacionOpcionGestionActuador{
    height: 24px;
    display: flex;
    width: 180px;
    padding-bottom: 32px;
    align-items: center;
}

#BoxOpcionesFormularioGestionActuador{
    width: 680px;
    height: 232px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--4, 32px);
}

#BoxTextFieldOpcionesFormularioGestionActuador{
    width: 680px;
    height: 56px;
    display: flex;
    align-items: flex-start;
    gap: var(--5, 40px);
}

#BoxOpcionTextFielFormularioGestionActuador{
    display: flex;
    width: 320px;
    height: 56px;
    flex-direction: column;
    align-items: flex-start;
}

#BoxSegundaInformacionOpcionGestionActuador{
    width: 896px;
    height: 56px;
    display: flex;
    align-items: flex-start;
    gap: 36px;
}

#BoxTituloSegundaInformacionOpcionGestionActuador{
    display: flex;
    width: 180px;
    height: 24px;
    padding-bottom: 32px;
    align-items: center;
}

#BoxSegundasOpcionesFormularioGestionActuador{
    display: flex;
    width: 680px;
    height: 56px;
    flex-direction: column;
    align-items: flex-start;
}

#TxtTitutlosFormulariosOpcionGestionActuador{
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
}

#BoxOpcionTipoDosTextFielFormularioGestionActuador{
    height: 56px;
    width: 320px;
    display: flex;
    align-items: flex-start;
    gap: var(--2, 16px);
}

#BoxOpcionTipoDos{
    display: flex;
    width: 190px;
    height: 56px;
    flex-direction: column;
    align-items: flex-start;
}

#BoxOpcionTipoTres{
    display: flex;
    width: 114px;
    height: 56px;
    flex-direction: column;
    align-items: flex-start;
}

#BoxHandlerFormularioOpcionGestionActuador{
    display: flex;
    height: 48px;
    width: 896px;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
}

#BoxTamanoBotonHandler{
    width: 152px;
    height: 48px;
}


#CardBox{
    position: relative;
}

#HeaderCard{
    position: absolute;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

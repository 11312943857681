
#CardOpcionesPerfil{
    width: 324px !important;
    height: 432px;
    border-radius: 16px;
}

#CardHeader{
    width: 296px;
    height: 48px;
    padding: 4px 4px 4px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

#CerrarIconPerfil{
    width: 10px;
    height: 10px;
    padding: 15px;
}

#PerfilContent{
    width: 276px;
    height: 336px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--2, 16px);
    align-self: stretch;
    padding-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
}

#BoxImagenDataPerfil{
    width: 276px;
    height: 148px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}

#BoxImagenPerfil{
    display: flex;
    justify-content: center;
}
#ImagenUsuarioPerfil{
    width: 80px;
    height: 80px;
    border-radius: 50px;
}

#TextoImagenPerfil{
    text-align: center;
}

#NombreUsuarioPerfil{
    color:#333;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
}

#TipoUSuarioEnPerfil{
    color: #626262;
    font-family: inherit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

#BoxOpcionesUsuario{
    width: 100%;
    height: 100%;
}

#botonPerfil{
    border-radius: 4px;
    width: 100%;
    padding: 10px 8px;
    gap: 8px;
    align-self: stretch;
}

#botonPerfil:hover{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    flex: 1 0 0;
    background: rgba(76, 76, 76, 0.12);
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    letter-spacing: 0.25px;
}






#BoxContenedorFiltrosDescarga{
    width: 300px !important;
    height: 600px;
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-start;
    align-items: center !important;
}

#BoxCabeceraCerrarFiltroDescarga{
    width: 300px;
    height: 48px;
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    align-items: center !important;
    /* border-bottom: 1px solid #B1B1B1; */
}

#TxtTituloCabeceraFiltroDescarga{
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; 
    letter-spacing: 0.15px;
}

#BoxTituloEnCabeceraFiltroDescarga{
    width: 260px;
    text-align: center;
}

#BoxContenedorIconoCierreFiltroDescarga{
    width: 40px;
    height: 48px;
}

#IconoCerrarFiltroDescarga{
    margin-top: 3px;
    width: 18px;
    height: 18px;
    padding: 4px;
}


#BoxLayoutContenidoFiltroDescarga{
    width: 240px;
    height: 522px;
    padding-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 19px
}

#BoxContenidoFormularioDescarga{
    width: 240px;
    height: 434px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
}


#BoxBotonAccionFormularioDescarga{
    width: 240px;
    height: 48px;
}

#BoxContenedorTipoUsuarioReporte{
    width: 240px;
    height: 56px;

}


/*desde aqui hacia abajo*/

#BoxOpcionesFiltrosDescarga{
    width: inherit;
    height: inherit;
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-end;
    gap: 20px;
}

#BoxContenedorSelectTipoUsuario, #BoxContenedorSelectTipoAcceso{
    width: 200px;
    height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center ;
    align-items: center ;
}

#BoxContenedorSelectZonasDescarga{
    width:230px;
    height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center ;
    align-items: center ;
}

#DescargaOpcionesZonas{
    padding: 4px 16px;
}


#BoxBotonAccionDescargarReporte{
    width: 175px;
    height: 48px;
    display: flex !important;
    flex-direction: row !important;
    align-items: flex-start;
    justify-content: flex-end !important;
}

#BoxBoton2AccionDescarga{
    width: 140px;
    height: 45px;
}


#BoxFechaInicioReporte{
    width: 220px;
    height: 45px;
    border-radius: 25px;
    background-color: #F8F8F8;
    border: 1px solid #333;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30),0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
}


#BoxFechaTerminoReporte{
    width: 220px;
    height: 45px;
    border-radius: 25px;
    background-color: #F8F8F8;
    border: 1px solid #333;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30),0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
}


#BoxLayoutExisteEnListaNegraGU{
    width: 896px;
    height: 416px;
    display: flex;
    padding-top: 9px;
    flex-direction: column;
    align-items: flex-start;
    gap: 88px;
}

#BoxLayouContenidoExisteEnListaNegraGU{
    width: 896px;
    height: 280px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--8, 64px);
}

#BoxContenidoExisteEnListaNegraGU{
    width: 896px;
    height: 160px;
    display: flex;
    align-items: flex-start;
    gap: var(--5, 40px);
}

#BoxTituloContenidoExisteEnListaNegraGU{
    width: 176px;
    height: 24px;
}

#TxtTituloExisteEnListaNegraGU{
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
}

#BoxDataExisteEnListaNegraGU{
    width: 680px;
    height: 280px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--6, 48px);
}

#BoxDatosFechaCreacionCreadorExisteEnListaNegraGU{
    width: 680px;
    height: 56px;
    display: flex;
    align-items: flex-start;
    gap: var(--5, 40px);
}

#BoxFechaCreacionExisteEnListaNegraGU{
    display: flex;
    width: 256px;
    height: 56px;
    flex-direction: column;
    align-items: flex-start;
}

#BoxCreadorExisteEnListaNegraGU{
    display: flex;
    width: 384px;
    height: 56px;
    flex-direction: column;
    align-items: flex-start;
}

#BoxMotivoIngresoExisteEnListaNegraGU{
    width: 680px;
    height: 100px;
}


#BoxAccionesExisteEnListaNegraGU{
    display: flex;
    width: 896px;
    height: 48px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

#BoxBtnRemoverExisteEnListaNegraGU{
    width: 176px;
    height: 48px;
}


#BoxLayoutAgregarListaNegraGU{
    width: 896px;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 200px;
}

#BoxDataCreacionAgregaListaNegraGU{
    width: 896px;
    height: 152px;
    display: flex;
    align-items: flex-start;
    gap: var(--5, 40px);
}

#BoxTituloDataAgregaListaNegraGU{
    width: 176px;
    height: 152px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#TxtTiuloAgregarListaNegraGU{
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
    padding-bottom: 32px;
}

#BoxMotivoAgregaListaNegraGU{
    display: flex;
    width: 680px;
    height: 152px;
    flex-direction: column;
    align-items: flex-start;
}

#BoxAccionesAgregarListaNegraGU{
    display: flex;
    width: 896px;
    height: 48px;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 16px;
}

#BoxBtnCancelarAgregarListaNegraGU{
    width: 152px;
    height: 48px;
}

#BoxBtnAgregarAgregarListaNegraGU{
    width: 152px;
    height: 48px;
}
#ModalDetalleRegistroTR{
    width: 240px !important;
    height: 320px !important;
    padding: 0;
    border: 0;
    border-radius: 16px;
}

#BoxDetalleRegistroTR, #CardDetalleRegistroTR{
    width: 240px !important;
    height: 320px !important;
    border-radius: 16px;
}

#BoxDetalleRegistroTiempoReal{
    height: 100px !important;
}

#Cabecera{
    width: 240px;
    display: flex;
    flex-direction: row !important;
    justify-content: flex-end;
    align-items: center !important;
}

#detalleNombres {
    color: #626262;
    font-family: inherit;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.25px;
}

#detalleRespuestas {
    color: #175676;
    font-family: inherit;
    font-size: 12px;
    font-style: normal;
    font-weight: 550;
    letter-spacing: 0.25px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

#BotonCierreDetalleTR{
    padding-top: 4px;
    padding-right: 4px;
    padding-left: 4px;
    padding-bottom: 0;

}


#iconoCierreDetalleTR{
    width: 10px;
    height: 10px;
    padding: 15px;
}

#IconoEspecialConexionConTabla{
    display: flex;
    position: absolute;
    right: -20px;
    margin-top: -30px;
}

#BoxDetalleTiempoReal{
    height: 100% !important;
}

#ContenidoTarjetaDetalleTR{
    padding-top: 0;
    height: 259px !important;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 16px;
}

#DetalleUsuarioRegistroTR{
    height: 230px !important; /*prueba*/
}

#NombreRutPatente{
    width: 100%;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 6px;
}


#NombreUsuarioRegistro{
    color: #175676;
    text-align: center;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 550;
    line-height: 24px;
    letter-spacing: 0.15px;
}

#RutPatente{
    color:#333;
    font-family: inherit;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.IconRespustaEnDetallePorAcceso{
    width: 18px !important;
    height: 18px !important;
}
#sentidoEnRegistro{
    font-family: inherit;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-right: 4px;
}



#BoxRespuestaEnDetalleTR{
    padding-top: 24px;
}

#GTipoDetalleTR{
    padding: 0;
    max-width: 70px !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

#GDetalleIngresoTR{
    min-width: 106px;
    max-width: 106px;
    padding: 0;
    display: flex !important;
    justify-content: left !important;
    align-items: center;
}

#GridGeneralDetalleTR{
    max-width: 176px;
    min-width: 176px;
    min-height: 111px;
    max-height: 11px;
}

#BoxTextoRespuestaAcceso{
    width: 176px;
    max-height: 48px;
}

#RespuestaASolicitudAcceso{
    color: #626262;
    width: 176px;
    font-family: inherit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
}
#BoxLayoutSinEventosView{
    width: 376px;
    height: 390px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#BoxCabeceraTituloSinEventosView{
    width: 100%;
    height: 65px;    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


#BoxContedorIconosFechaSinEventosView{
    width: 346px;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding-left: 30px;
}

#BoxIconoFechaActualSinEventosView{
    width: 205px;
}

#BoxIconosAccionSinEventosView{
    width: 80px;
    height: 40px;
    gap: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

#BoxCuerpoSinEventosView{
    width: 316px;
    height: 264px;
    padding: 30px 30px 30px 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

#BoxCuerpoSinEventosViewTipo2{
    width: 316px;
    height: 234px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 15px;
    max-height: 234px;
    min-height: 234px;
    overflow-y: auto;
    overflow-x: hidden;
}

#BoxCuerpoSinEventosViewTipo2::-webkit-scrollbar{
    width: 7.415px !important;
    background: transparent !important;
}

#BoxCuerpoSinEventosViewTipo2::-webkit-scrollbar-track{
    background: transparent !important;
}

#BoxCuerpoSinEventosViewTipo2::-webkit-scrollbar-thumb{
    background: #C9C9C9;
    border-radius: 16px;
    
}

#BoxFinalVistaSinEventosViewTipo2{
    width: 316px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

#TxtFechaFormateadaSinEventosViewTipo2{
    color: #175676;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
}


#BoxContenidoSinEventosView{
    width: 316px;
    height: 200px;

}

#BoxTextoSinEventosView{
    width: 316px;
    height: 55px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#ImgNoEvento{
    width: 318px;
    height: 199px;
}

#IconButtonNoEventosView{
    width: 14px;
    height: 14px;
    padding: 4px;
}


#TxtFechaFormateadaSinEventosView{
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
    letter-spacing: 0.15px;
}

#TxtMensajeSinEventosView{
    color: #626262;
    font-family: inherit;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
    letter-spacing: 0.15px;
}


/* css vista con evento*/

#BoxLayoutEventoView{
    width: 376px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#BoxCabeceraTituloEventoView{
    width: 100%;
    height: 65px;    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#BoxContedorIconosFechaEventoView{
    width: 346px;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    padding-left: 30px;
}


#BoxIconoFechaActualEventoView{
    width: 185px;
}


#BoxIconosAccionEventoView{
    width: 90px;
    height: 40px;
    gap: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}


#BoxContenidoEventoView{
    width: 332px;    
    padding: 20px 20px 20px 20px;
}

#BoxContenidoEventoDetalle{
    width: 332px;    
    height: 283px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
}
















#BoxContenidoDetalleCreadorEvento{
    width: 274px;
    height: 120px;
    padding-left: 29px;
    padding-right: 29px;
}

#BoxDetalleCreacionInvitacionEventosView{
    width: 274px;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
#BoxInformacionCreacionEventoView{
    width: 274px;
    height: 24px;
    gap: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

#BoxTxtTituloCreacionEventoView{
    width: 90px;
}

#TxtTituloCreacionEventoView{
    color: #626262;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    font-weight: 400;
}

#BoxTxtCreadorEventoView{
    width: 186px;
    min-width: 186px;
    max-width: 186px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

}
#TxtCreadorEventoView{
    color: #333;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    font-weight: 400;
}


#BoxBtnMostrarInvitados{
    width: 332px;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#BtnVerInvitadosEventosView{
    padding: 0px;
}

#TxtVerInvitadosEventosView{
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
    font-weight: 400;
    text-transform: none;
}

#BoxContenidoInvitados{
    width: 322px;
    height: 212px;
    display: inline-flex;
    border-radius: 8px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;
    padding-left: 10px;
    max-height: 212px;
    overflow-y: scroll;
    overflow: hidden;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
}

#BoxCabeceraListaInvitados{
    width: 322px;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

#BoxCabeceraNombreInvitado{
    width: 160px;
}

#LblTituloNombreInvitado{
    color: #333;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    font-weight: 500;
}



#BoxCabeceraIdentificadorInvitado{
    width: 157px;
}

#LblTituloIdentificadorInvitado{
    color: #333;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    font-weight: 500;
}

#BoxContenedorItemListaInvitadoEventosView{
    width: 322px;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

#BoxNombreInvitadoListaInvitadosEventosView{
    width: 160px;

}

#TxtNombreApellidoInvitadoEventoView{
    color: #175676;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    font-weight: 400;
    text-transform: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

#BoxRutPasaporteInvitadoListaInvitadosEventosView{
    width: 157px;

}

#TxtRutPasaporteInvitadoEventoView{
    color: #175676;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    font-weight: 400;
    text-transform: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
#CabeceraEditarUsuario{
    display: flex;
    padding: var(--2, 16px) var(--2, 16px) var(--none, 0px) var(--2, 16px);
    justify-content: flex-end;
    align-items: center;
    gap: var(--3, 24px);
    align-self: stretch;
}


#ContenidoModalEditarUsuario{
    width: 1016px;
    height: 486px;
    display: flex;
    padding: var(--none, 0px) var(--8, 64px) var(--8, 64px) var(--8, 64px);
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

#BoxTitutloModalEditarUsuario{
    display: flex;
    width: 1016px;
    padding-bottom: 16px;
    align-items: flex-start;
    gap: 8px;
    border-bottom: 1px solid #B1B1B1;
}

#TituloEditarUsuario{
    display: flex;
    height: 48px;
    justify-content: center;
    align-items: center;
    color: #333;
    font-family: inherit;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
}

#BoxSubtituloModalEditarUsuario{
    width: 1016px;
    height: 24px;
    display: flex;
    padding: 8px 0px;
    padding-bottom: 30px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}


#SubtituloEditarUsuario{
    color: #8A8A8A;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
}

#BoxFormEditarUsuario{
    width: 1016px;
    height: 290px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;  
}

#BoxInformacionPersonalEditar{
    display: flex;
    align-items: flex-start;
    gap: var(--5, 40px);
    width: 100%;
    height: 144px;
}

#TituloInformacionPersonalEditar{
    display: flex;
    width: 200px;
    padding-bottom: 24px;
    align-items: center;
}


#TxtTituloInformacionPersonalEditar{
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
}

#BoxFormularioInformacionPersonalEditar{
    width: 776px;
    height: 144px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--5, 40px);
}

#BoxNombreApellidoEditar{
    width: 776px;
    height: 52px;
    display: flex;
    align-items: flex-start;
    gap: var(--5, 40px);
}
#BoxRutTipoEditar{
    width: 776px;
    height: 52px;
    display: flex;
    align-items: flex-start;
    gap: var(--5, 40px);
}

#selectTipoUsuarioEditar{
    width: 368px;
    height: 48px;
    padding: 2px 0px;
}

#BoxInformacionContactoEditar{
    width: 100%;
    height: 52px;
    display: flex;
    align-items: flex-start;
    gap: var(--5, 40px);
}

#TituloInformacionContactoEditar{
    display: flex;
    width: 200px;
    height: 52px;
    align-items: center;
}

#TxtTituloInformacionContactoEditar{
    color: #175676;
    padding-bottom: 24px;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
}



#BoxFormularioInformacionContactoEditar{
    height: 52px;
    width: 776px;
    display: flex;
    align-items: flex-start;
    gap: var(--5, 40px);
}

#BotoncesAccionModalNuevoUsuarioEditar{
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

#BoxBotonesEditar{
    width: 368px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#BoxBotonNuevoUsuario{
    width: 176px !important;
    height: 48px;
}


#BoxItemTipoUsuarioEditar{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 15px;
}

#IconoEditarCorreo{
    border-radius: 100px;
    width: 30px;
    height: 30px;
    padding: 4px;
    background-color: black;
}

#IconoLimpiarEnEditarUsuario{
    width: 20px;
    height: 20px;
    padding: 4px;
}

#BoxCorreoEditarBoton{
    width: 370px;
    height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
#BoxTelefonoNuevo{
    width: 370px;
    height: 48px;

}

#BotonEditarCorreoEditarUsuario{
    display: flex;
    text-transform: none;
    color: #626262;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
    border-radius: 4px;
    margin-top: 4px;
    height: 20px;
}

#BoxBotonEditarcorreoEditarUsuario{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
#IconoEditarCorreoEditarUsuario{
    width: 12px;
    height: 12px;
    padding: 3px;
}


#iconoEnDialogConfirmacionEditarUsuario{
    width: 88px;
    height: 88px;
}

#BoxIdentificadoresEditarUsuario{
    width: 368px;
    height: 48px;
    display: flex;
    align-items: center;
    gap: 20px;
}

#BoxSelectTipoPersona{
    width: 368px;
    height: 48px;
}

#BoxIdentificadorRutEditarUsaurio{
    width: 174px;
    height: 48px;

}
#BoxIdentificadorPasaporteEditarUsaurio{
    width: 174px;
    height: 48px;

}

#BoxCabeceraAgregarAListaNegra{
    width: 992px;
    height: 48px;
    display: flex;
    padding: var(--2, 16px) var(--2, 16px) var(--none, 0px) var(--2, 16px);
    justify-content: flex-end;
    align-items: center;
    gap: var(--3, 24px);
    align-self: stretch;
}
#BoxContenidoOpcionAgregarAListaNegra{
    width: 896px;
    height: 552px;
    display: flex;
    padding: var(--none, 0px) var(--8, 64px) var(--8, 64px) var(--8, 64px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--6, 48px);
}

#BoxAgregarAListaNegra{
    width: 896px;
    height: 552px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--3, 24px);
}

#BoxTituloSubtituloAgregarAListaNegra{
    width: 896px;
    height: 96px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

#BoxTituloAgregarAListaNegra{
    width: 896px;
    display: flex;
    height: 40px;
    padding-bottom: 16px;
    align-items: flex-start;
    gap: 50px;
    align-self: stretch;
    border-bottom: 1px solid #B1B1B1;
}

#TxtTituloAgregarAListaNegra{
    color: #333;
    font-family: inherit;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 125% */
}

#BoxSubTituloAgregarAListaNegra{
    display: flex;
    width: 896px;
    height: 20px;
    padding: 10px 0px;
    align-items: center;
    align-self: stretch;
}

#TxtSubTituloAgregarAListaNegra{
    color: #8A8A8A;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
}

#BoxFormularioAgregarAListaNegra{
    width: 896px;
    height: 432px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;
}

#BoxContenidoFormularioAgregarAListaNegra{
    width: 896px;
    height: 336px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--6, 48px);
}

/* contenido del formulario*/

#BoxContenidoFormularioUsuariosAListaNegra{
    width: inherit;
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 36px;
}

#BoxTituloUsuariosIngresoListaNegra{
    display: flex;
    width: 180px;
    height: 24px;
    padding-bottom: 32px;
    align-items: center;
}
#BoxUsuariosIngresoAListaNegra{
    display: flex;
    width: 680px;
    flex-direction: column;
    align-items: flex-start;
}

#BoxContenidoFormularioInvitadosAListaNegra{
    width: inherit;
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 36px;
}

#BoxTituloInvitadosIngresoListaNegra{
    display: flex;
    width: 180px;
    height: 24px;
    padding-bottom: 32px;
    align-items: center;
}
#BoxInvitadosIngresoAListaNegra{
    display: flex;
    width: 680px;
    flex-direction: column;
    align-items: flex-start;
}


#BoxContenidoFormularioMotivoIngreso{
    width: inherit;
    height: 102px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 36px;
}

#BoxTituloMotivoIngresoListaNegra{
    display: flex;
    width: 180px;
    height: 24px;
    padding-bottom: 32px;
    align-items: center;
}

#BoxMotivoIngresoAListaNegra{
    display: flex;
    width: 680px;
    flex-direction: column;
    align-items: flex-start;
}

/*botones de accion*/


#BoxHandlerFormularioAgregarAListaNegra{
    display: flex;
    height: 48px;
    width: 896px;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
}

#BoxBotonesAccionAgregarAListaNegra{
    width: 152px;
    height: 48px;
}




#TxtOpcionTituloFormulario{
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
}
#BoxLayoutVistaDia{
    width: inherit;
    height: 616px;
}

#BoxVistaCalendarioDia{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    max-height: 616px;
    overflow-y: scroll;
    overflow-x: hidden;
    background: transparent;
}

#BoxVistaCalendarioDia::-webkit-scrollbar{
    width: 7.024px !important;
    background: transparent !important;
} 

#BoxVistaCalendarioDia::-webkit-scrollbar-track{
    background: transparent !important;
}

#BoxVistaCalendarioDia::-webkit-scrollbar-thumb{
    background: #C9C9C9;
    border-radius: 100px;
}


#ContenedorHoras{
    width: 100px;
    height: 100%;
}

#GridItemAccion{
    width: 100px;
    height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#HoraDia{
    width: 100px;
    height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #94B1C0;
}

#BoxContenedorUbicacionesColumna{
    width: 100%;
    min-width: 1330px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}


#GridContenedorUbicacion{
    width: 190.4px;
    min-width: 190.4px;
    height: calc(48*24px);
}

#GridNombreUbicacion{
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#GridListaDeHorasUbicacion{
    border-top: 1px solid #94B1C0;
    width: 100%;
    height: 100%;
}


#TxtNombreUbicacionEmpresaVistaDiaAgenda{
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
}

#TxtHoraDiaVistaDiaAgenda{
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
}


#iconoAccionCambioVistaDiaAgenda{
    width: 20px;
    height: 20px;
    padding: 8px;
}
#ContainerModalVerDetallesTarea{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    background-color: white;
    border-radius: 16px;
    padding: 15px 30px 30px 30px;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-width: 450px;
}

#CabeceraModalVerDetallesTarea{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    border-bottom: 1px solid #B1B1B1
}

#CuerpoModalVerDetallesTarea{
    display: flex;
    flex-direction: column;
    background-color: transparent;
    border-radius: 16px;
    padding-bottom: 20px;
}

.BoxItemInfoTarea{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.TituloInfoTarea{
    color: #175676;
    display: flex;
    align-items: center;
}

#BoxInfoDispositivo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.BoxItemInfoDispositivo{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

#BoxContenidoInfoTarea{
    display: flex;
    flex-direction: column;
    gap: 30px;
    border-radius: 16px;
}

#BoxBotonAceptarVerDetallesTarea{
    width: 50%;
    margin: auto;
}
#ContentRespuesta{
    width: 324px;
    height: 316px;
    padding: 60px 40px 40px 40px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 88px;
}

#BoxRespuesta{
    width: 324px;
    height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--4, 32px);
}
#BoxIconoRespuesta{
    display: flex;
    padding: 4px;
    align-items: flex-start;
    gap: 10px;
}

#MediaRespuestaServidor{
    width: 96px;
    height: 96px;
}

#IconoRespuestaServidor{
    width: 88px;
    height: 88px;
    padding: 4px;
}


#TipoRespuestaServidor{
    width: 324px;
    height: 92px;
    display: flex;
    width: 324px;
    flex-direction: column;
    align-items: center;
    gap: var(--2, 25px);
}

#BoxTituloRespuesta{
    width: 324px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}
#TituloRespuestaServidor{
    color: #333;
    font-family: inherit;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; 
}

#BoxTextoRespuesta{
    /* height: 40px; */
    display: flex;
    width: 324px;
    justify-content: center;
    align-items: center;
}

#TextoRespuestaServidor{
    color: #626262;
    text-align: center;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;

    overflow: hidden;
    text-overflow: ellipsis;
    height: 102px;
}

#BoxAccionesRespuestaServidor{
    display: flex;
    width: 324px;
    height: 48px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

#BoxAccionesRespuestaServidorDos{
    width: 324px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}




#ContentRespuestaDos{
    width: 324px;
    padding: 80px 40px 40px 40px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;
}


#BoxBotonUno{
    width: 324px;
    height: 48px;
}
#BoxBotonDos{
    width: 324px;
    height: 48px;
    margin-top: 25px;
}

#ContentRespuestaTres{
    width: 324px;
    padding: 80px 40px 40px 40px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;
}
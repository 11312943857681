#BoxCabeceraOpcionHoy{
    width: inherit;
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#TextoHoyFormateado{
    color: #333;
    font-family: inherit;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    
}


#BoxCabeceraOpcionSemana{
    width: inherit;
    height: inherit;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

#BoxContenedorHandlerMes{
    width: 211px;
    height: inherit;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

#BoxContenedorHandlerYear{
    width: 165px;
    height: inherit;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}


#iconoAccionVistaSemanaAgenda{
    width: 16px;
    height: 16px;
    padding: 10px;
    margin: 0px;
}



#BoxCabeceraOpcionAnio{
    width: inherit;
    height: inherit;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#BoxContenedorHandlerAnio{
    width: 300px;
    height: inherit;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
}


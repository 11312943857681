
#Header{
    align-items: center;
    width: 300px;
    display: flex;
    height: 48px;
    flex-direction: row;
    justify-content: space-between;
    background: #8A8A8A;
    border-radius: 8px;
}

#TxtTituloFiltroTiempoReal{
    color: #FFF;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-left: 16px;
}

#ContainerMenu {
    width: 300px;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 8px;
}

#CerrarMenuIcon {
    height: 10px;
    padding: 15px;
    width: 10px;
    color: white !important;
}

#BoxMenuItem{
    width: 260px;
    height: 240px;
    max-height: 240px;
    padding: 0px 20px 20px 20px;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#BoxMenuItem::-webkit-scrollbar{
    width: 7.024px !important;
    background: transparent !important;
} 

#BoxMenuItem::-webkit-scrollbar-track{
    background: transparent !important;
}

#BoxMenuItem::-webkit-scrollbar-thumb{
    background: #C9C9C9;
    border-radius: 100px;
}


#BoxItemFiltro{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 290px;
    height: 48px;
    gap: 5px;
}

#BorrarFiltroIcon {
    height: 40px;
    width: 40px;
    color: white;
}

#BoxTotalFiltrosAplicados{
    width: 300px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}



#TxtTipoFiltroNombreFiltroAplicado{
    color: #333;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


#iconoEnTablaaFiltros{
    width: 15px;
    height: 15px;
    padding: 4px;
    color: #333;
}


#ItemFiltro{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 290px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
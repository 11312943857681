#Litem{
    padding-bottom: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

#ItemButtonIcontext{
    height: 48px !important;
    min-height: 48px;
    max-height: 48px !important;
    padding-right: 16px;
    display: flex !important;
    justify-content: center !important;
    align-items: center;
}

#iconoMenu{
    width: 18px;
    height: 20px !important;
    max-width: 18px;
    min-width: 18px;
    padding-left: 48px;
    align-items: center;
    padding-right: 8px !important;
}

#OnlyIconMenu{
    height: 48px !important;
    min-height: 48px;
    max-height: 48px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center;
}

#IconoMenu{
    width: 18px;
    height: 20px !important;
    max-width: 18px;
    min-width: 18px;
    align-items: center;
    padding-right: 8px !important;
}

#SubMenuReporte{
    max-height: 100px; 
    overflow-y: hidden;
    overflow-x: hidden;
}

#SubMenuConfig{
    overflow-y: scroll;
    overflow-x: hidden;
}
#SubMenuConfig::-webkit-scrollbar{
    width: 7.415px !important;
    background: transparent !important;
}

#SubMenuConfig::-webkit-scrollbar-track{
    background: transparent !important;
}

#SubMenuConfig::-webkit-scrollbar-thumb{
    background: #C9C9C9;
    border-radius: 16px;
    
}


#SMListClosedMenu{
    width: 184px;
    height: auto;
    display: inline-flex;
    padding: 16px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.30);
}

#BoxCabeceraEstacionamiento{
    width: 1077px;
    height: 48px;
    display: flex;
    padding: var(--2, 16px) var(--2, 16px) var(--none, 0px) var(--2, 16px);
    justify-content: flex-end;
    align-items: center;
    gap: var(--3, 24px);
    align-self: stretch;
}


#BoxOpcionesGestionEstacionamientos{
    width: 980px;
    height: 552px;
    display: flex;
    padding: var(--none, 0px) var(--8, 64px) var(--8, 64px) var(--8, 64px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--6, 48px);
}

#BoxContenedorNuevoEstacionamiento{
    width: 980px;
    height: 552px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 30px;
}


#BoxTituloSubTituloNuevoEstacionamiento{
    width: 980px;
    height: 96px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}



#BoxTituloOpcionGestionEstacionamientos{
    width: 980px;
    height: 41px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid #8A8A8A;
}

#TxtTituloGestionEstacionamiento{
    color: #333;
    font-family: inherit;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 125% */
}

#BoxSubTituloOpcionGestionEstacionamientos{
    width: 980px;
    height: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

#TxtSubTituloGestionEstacionamiento{
    color: #8A8A8A;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
}


#BoxInformacionNuevoEstacionamiento{
    width: 980px;
    height: 429px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
}

#BoxContenidoInformacionEstacionamiento{
    width: inherit;
    height: 348px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 40px
}

#BoxContenidoZonaNombreEstacionamiento{
    width: 980px;
    height: 154px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 40px;
}
#BoxContenidoAsignacionEstacionamiento{
    width: 980px;
    height: 154px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 40px;
}

#BoxTituloNuevoEstacionamientoFormato{
    width: 250px;
    height: 154px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 40px;
}

#BoxFormularioNuevoEstacionamiento{
    width: 692px;
    height: 154px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 42px;
}

#BoxContenedorOpcionesFormularioNuevoEstacionamiento{
    width: 692px;
    height: 54px;
}
#IconoEnEstacionamiento{
    width: 23px;
    height: 23px;
    padding: 6px;
}
#BoxConfirmarNuevoUsuario{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 32px;
}

#CardCambioConfirmarNuevoUsuario{
    width: 624px;
    height: 920px;
    border-radius: 32px;
}

#CardContenidoConfirmarNuevoUsuario{
    width: 408px;
    height: 488px;
    padding-top: 212px;
    padding-bottom: 220px;
    padding-left: 108px;
    padding-right: 108px;
}

#BoxImagenConfirmarNuevoUsuario{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#CP2Confirmar{
    width: 256px;
    height: 152px;
}

#BoxTituloConfirmarNuevoUsuario{
    width: 100%;
    text-align: center;
    padding-top: 48px;
}

#TextoTituloConfirmarNuevoUsuario{
    color: #175676;
    text-align: center;
    font-family: inherit;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
}

#BoxCorreoNuevoUsuario{
    width: 100%;
    height: 300px;
    gap: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 30px;
}

#BoxBotonConfirmarNuevoUsuario{
    padding-top: 30px;
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
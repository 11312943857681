#BoxZonas{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;
}

#GestionZonasIcon{
    width: 25px;
    height: 25px;
}


#iconoEnTablaZonas{
    width: 40px;
    height: 40px;
    padding: 0px;
}

#IconoEnTFUnoZona{
    width: 20px;
    height: 20px;
    padding: 8px;
}

#BoxFiltroEtadoSelectCabeceraZona{
    width: 250px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#FiltroSelectOpcionZona{
    width: 250px;
}
#BoxLayoutCrearNuevo{
    width: 896px;
    height: 530px;
    display: flex;
    padding: var(--none, 0px) var(--8, 64px) 52px var(--none, 0px);
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: var(--6, 48px);
}

#BoxTituloLayoutCrearNuevo{
    width: 896px;
    height: 40px;
    display: flex;
    padding-bottom: 16px;
    align-items: flex-start;
    gap: 8px;
    border-bottom: 1px solid var(--Controlpass-Basic-grey-300, #B1B1B1);
}

#TxtTituloLayoutCrearNuevo{
    color: #333;
    font-family: inherit;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    flex: 1 0 0;
}

#BoxSubTituloLayoutCrearNuevo{
    width: 896px;
    height: 20px;
    display: flex;
    padding: 10px 0px;
    align-items: center;
    align-self: stretch;
}

#TxtSubTituloLayoutCrearNuevo{
    color: #8A8A8A;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
    flex: 1 0 0;
}


#BoxContenidoLayoutCrearNuevo{
    width: 896px;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}



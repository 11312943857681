#BoxHorarioGestionUsuario{
    width: 894px;
    height: 530px;
    display: flex;
    padding: var(--none, 0px) var(--8, 64px) 52px var(--none, 0px);
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: var(--6, 48px);
}

#BoxTextoTituloHorarioGU{
    display: flex;
    width: 894px;
    height: 40px;
    padding-bottom: 16px;
    align-items: flex-start;
    gap: 8px;
    border-bottom: 1px solid #B1B1B1;
    flex: 1 0 0;
}

#TxtTituloHorarioGU{
    color: #333;
    font-family: inherit;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
}

#BoxTablaHorarioGU{
    display: flex;
    width: 893px;
    height: 424px;
    flex-direction: column;
    align-items: flex-start;
    gap: 68px;
}

#DataGridHorariosGU{
    width: inherit;
    height: inherit;
}




#BoxLayoutAsignarHorarioGU{
    width: 896px;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 192px;
}

#BoxTituloSeleccionAsignacionHorarioGU{
    width: 896px;
    height: 160px;
    display: flex;
    align-items: flex-start;
    gap: var(--5, 40px);
}

#BoxTituloAsignacionHorarioGU{
    width: 172;
    height: 160px;
}

#TxtTituloAsignacionHorarioGU{
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    padding-bottom: 32px
}

#BoxSeleccionAsignacionHorarioGU{
    width: 684px;
    height: 160px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--6, 48px);
}

#BoxSelectHorarioAsignacionGU{
    width: 684px;
    height: 56px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
}

#BoxFechaInicioTerminoAsignacionHorarioGU{
    width: 684px;
    height: 56px;
    display: flex;
    align-items: flex-start;
    gap: 28px;
}

#BoxFechaInicioAsignacionHorarioGU{
    width: 328px;
    height: 56px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#BoxFechaTerminoAsignacionHorarioGU{
    width: 328px;
    height: 56px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#BoxAccionesAsignarHorarioGU{
    display: flex;
    width: 896px;
    height: 48px;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 16px;
}



#BoxBtnCancelarAsignarHorarioGU{
    width: 152px;
    height: 48px;
}


#BoxBtnAsignarAsignarHorarioGU{
    width: 152px;
    height: 48px;
}

#CabeceraNuevaAsignacionPase{
    width: 992px;
    height: 48px;
    display: flex;
    padding: var(--2, 16px) var(--2, 16px) var(--none, 0px) var(--2, 16px);
    justify-content: flex-end;
    align-items: center;
    gap: var(--3, 24px);
    align-self: stretch;
}


#ContenidoModalNuevaAsignacionPase{
    width: 896px;
    height: 552px;
    display: flex;
    padding: var(--none, 0px) var(--8, 64px) var(--8, 64px) var(--8, 64px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--5, 40px);
}

#BoxAgregarNuevaAsignacionPase{
    width: 896px;
    height: 552px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--3, 24px);
}

#BoxTituloSubtituloOpcionNuevaAsignacionPase{
    width: 896px;
    height: 96px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

#BoxTituloOpcionNuevaAsignacion{
    width: 896px;
    display: flex;
    height: 40px;
    padding-bottom: 16px;
    align-items: flex-start;
    gap: 50px;
    align-self: stretch;
    border-bottom: 1px solid #333;
    flex-direction: row;
}

#TxtTituloOpcionNuevaAsignacion{
    color: #333;
    font-family: inherit;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 125% */
}


#TxtSubTituloOpcionNuevaAsignacion{
    color: #8A8A8A;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
}



#BoxFormularioOpcionNuevaAsignacionPase{
    width: 896px;
    height: 432px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;
}

/* contenido del formulario */
#BoxContenidoFormularioOpcionNuevaAsignacionPase{
    width: 896px;
    height: 336px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--6, 30px);
}


#BoxInformacionTarjetaNuevaAsociacionPase{
    width: 896px;
    height: 56px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 36px;
}

#BoxTituloInformacionTarjetaOpcionNuevaAsociacionPase{
    width: 260px;
    height: 56px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#BoxContenidoInformacionTarjetaOpcionNuevaAsociacionPase{
    width: 600px;
    height: 56px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 30px;
}

#TxtTituloInformacionTarjetaNuevaAsignacionPase{
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
}

#BoxCodigoTarjetaOpcionNjuevaAsociacionPase{
    width: 285px;
    height: 48px;

}

#BoxTipoTarjetaOpcionNuevaAsociacionPase{
    width: 285px;
    height: 48px;

}


#BoxInformacionUsuarioNuevaAsociacionPase{
    width: 896px;
    height: 56px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 36px;
}


/* detalle del box de opciones creacion/cancelar */


#BoxHandlerFormularioOpcionNuevaAsignacionPase{
    display: flex;
    height: 48px;
    width: 896px;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
}


#BoxTamanoBotonHandler{
    width: 152px;
    height: 48px;
}



#BoxMotivoListaNegra{
    width: 180px;
    height: 280px;
    padding: 20px 20px 20px 20px;
}

#BoxListaNegraDetalleMotivo {
    width: 180px;
    height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
}

#BoxTituloMotivoIngreso {
    width: 180px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#TxtTituloMotivoIngresoListaNegra {
    width: 180px;
    text-align: center;
    border-bottom: 1px solid #175676;
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
}

#TextoMotivoIngresoListaNegra {
    height: 220px;
    text-align: center;
    width: 195px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: normal;
    flex-grow: 1;
    overflow-y: auto;
    max-height: 180px;
}

#TxtMotivoIngresoListaNegra {
    color: #626262;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    width: 180px;
    height: 180px;
}


#BoxImagenContactoVentanaDos{
    width: 678px;
    height: 910px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 0px 32px 32px 0px;
}

#BoxImagenVentanaDosLogin{
    width: 100%;
    height: 100%;
}

#ImagenLoginTorLau{
    height: 100%;
    width:100%;
    border-radius: 0px 32px 32px 0px;
}

#BoxIconoToContacto{
    width: 64px;
    height: 64px;
    display: flex;
    position: absolute;
    top: auto;
    right: 16px;
    left: auto;
    bottom: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
}



#BoxContactanosLogin{
    width: 677px;
    height: 910px;
    border-radius: 0px 32px 32px 0px;
    background-color: #175676;
    transition: opacity 0.5s ease-in-out;
    position: absolute;
    right: 0;
}

#BoxCabeceraContactanosLogin{
    width: 677px;
    height: 87px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

#IconButtonCerrarContacto{
    margin-right: 26px;
}

#BoxContenidoContactanosLogin{
    width: 408px;
    height: 709px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 114px;
    padding-left: 153px;
    padding-right: 116px;
}

#BoxContenidoFormularioLogin{
    width: 408px;
    height: 709px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 36px;
}

#BoxFormularioContactanosLogin{
    width: 408px;
    height: 367px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;
}

#BoxTituloFormularioLogin{
    width: 408px;
    height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#TxtTituloFormularioLogin{
    color: #fff;
    font-family: inherit;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px;
}

#BoxFormularioLogin{
    width: 408px;
    height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

#BoxTxtfieldNombreLogin{
    width: 408px;
    height: 48px;
}
#BoxTxtfieldMotivoLogin{
    width: 408px;
    height: 48px;
}
#BoxTxtfieldMensajeLogin{
    width: 408px;
    height: 105px;
}

#BoxBtnAccionFormularioLogin{
    width: 408px;
    height: 48px;
}

#BoxContenidoDataEncuentranosLogin{
    width: 408px;
    height: 303px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;
}

#BoxContenidoDataTituloEncuentranos{
    width: 408px;
    height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#TxtContenidoDataTituloEncuentranos{
    color: #fff;
    font-family: inherit;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px;
}

#BoxContenidoDataUbicacionEncuentranos
{
    width: 408px;
    height: 25px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

#BoxIconoUbicacionContactanos{
    width: 25px;
    height: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#IconBtnUbicacionContactanos{
    padding: 0px;
}
#IconoUbicacionContactanos{
    width: 25px;
    height: 25px;
    padding: 0px;
}
#BoxUbicacionEmpresaTexto{
    width: 368px;
    height: 25px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

#TxtUbicacionEmpresa{
    color: #FFF;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
    padding-left: 5px;
}

#BoxContenidoDataVentasSoporteEncuentranos
{
    width: 408px;
    height: 110px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 53px;
}

#BoxContenidoDataSoporteEncuentranos{
    width: 176px;
    height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

#BoxContenidoDataSoporteTituloEncuentranos{
    width: 176px;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

#TxtTituloSoporteEncuentranos{
    color: #fff;
    font-family: inherit;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 127.273% */
}

#BoxContenidoDataSoporteCorreoEncuentranos{
    width: 176px;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

#TxtCorreoSoporteEncuentranos{
    color: #FFF;
    font-family: inherit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
}

#BoxContenidoDataSoporteTelefonoEncuentranos{
    width: 176px;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

#TxtTelefonoSoporteEncuentranos{
    color: #FFF;
    font-family: inherit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
}

#BoxContenidoDataVentasEncuentranos{
    width: 176px;
    height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

#BoxContenidoDataVentasTituloEncuentranos{
    width: 176px;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
#BoxContenidoDataVentasCorreoEncuentranos{
    width: 176px;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
#BoxContenidoDataVentasTelefonoEncuentranos{
    width: 176px;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

#BoxContenidoDataRedesSocialesEncuentranos
{
    width: 408px;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#IconBtnRrssCp{
    width: 40px;
    height: 40px;
    padding: 0px;
}
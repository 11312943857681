#BoxContenedorTablaPatentesEmpresa{
    width: inherit;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;
}



#BoxContenedorValidacionesEvento{
    display: flex;
    width: 624px;
    height: 920px;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFF;
    border-radius: 32px;
}

#BoxContenedorConfirmarAsistencia{
    width: 408px;
    height: 684px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--2, 16px);
    position: absolute;
    right: 107px;
    bottom: 90px;
}


#BoxImagenMasContenidoConfirmarAsistencia{
    width: 408px;
    height: 628px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--6, 48px);
}

#BoxContenedorImagenConfirmacionAsistencia{
    width: 408px;
    height: 152px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}


#ImagenLogoCP2ConfirmacionAsistencia{
    width: 256px;
    height: 152px;
}

#BoxInformacionEventoConfirmarAsistencia{
    width: 408px;
    height: 428px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--4, 32px);
}

#BoxContenedorInformacionEventoIdentificadorConfirmarAsistencia{
    width: 100%;
    height: 348px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 23px;
}


#BoxAccionesConfirmarAsistencia{
    width: 100%;
    height: 48px;
}

#BoxTitulosConfirmacionAsistenciaEvento{
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#BoxContenedorTituloSaludoConfirmacionEvento {
    width: 100%;
    height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#BoxContenedorSubtituloSaludoConfirmacionEvento {
    width: 100%;
    height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#BoxContenedorNombreEventoSaludoConfirmacionEvento {
    width: 100%;
    height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#TxtTituloConfirmacionEvento{
    flex: 1 0;
    color: #175676;
    text-align: center;
    font-family: inherit;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
}

#TxtSubtituloConfirmacionEvento{
    flex: 1 0;
    color: #175676;
    text-align: center;
    font-family: inherit;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
}

#TxtNombreEventoConfirmacionEvento{
    flex: 1 0;
    color: #175676;
    text-align: center;
    font-family: inherit;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


#BoxDataEventoNotaIdentificadorConfirmacionAsistencia{
    width: 100%;
    height: 248px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

#BoxContenedorInformacionEventoConfirmarAsistencia{
    width: 100%;
    height: 112px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#BoxNotaEventoConfirmarAsistencia{
    width: 100%;
    height: 40px;
    text-align: center;

}

#BoxIdentificacionConfirmarAsistencia{
    width: 100%;
    height: 56px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}



#TxtNotaEventoConfirmarAsistencia{
    flex: 1 0 0;
    color: #333;
    text-align: center;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
}



#BoxInformacionEventoMasIconoConfirmarAsistencia{
    width: 100%;
    height: 24px;
    display: flex;
    align-items: center;
    gap: var(--2, 16px);
}


#iconoConfirmarAsistencia{
    width: 18px;
    height: 18px;
}


#TextoInformacionEventoConfirmarAsistencia{
    color: #333;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


#BoxContenedorImagenFormInvitado{
    width: 408px;
    height: 790px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--2, 16px);
    position: absolute;
    right: 107px;
    bottom: 70px;
}

#BoxImagenCp2FomularioInvitado{
    width: 100%;
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#LogoCp2{
    width: 300px;
    height: 180px;
}

#BoxContenidoFormularioInvitado{
    width: 100%;
    height: 594px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
}



#BoxBotonesAccionFormularioInvitado{
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
}

#BoxBtnFormularioInvitado{
    width: 190px;
    height: 48px;
}


#BoxFormularioInvitado{
    width: 100%;
    height: 522px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

#BoxTituloSubtituloFormularioInvitado{
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

#BoxTituloFormularioInvitado{
    width: 100%;
    height: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#BoxSubtituloFormularioInvitado{
    width: 100%;
    height: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}


#BoxCuerpoFormularioInvitado{
    width: 100%;
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;
}

#BoxContenedorTFTipoUno{
    width: 100%;
    height: 45px;

}



#txtTituloFormularioInvitado{
    color: #175676;
    text-align: center;
    font-family: inherit;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
}


#txtSubtituloFormularioInvitado{
    color: #175676;
    text-align: center;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 133.333% */
}

#AgendarEventoBox{
    width: 376px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background:  #FFF;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
}

#iconoTipoVisita{
    width: 15px;
    height: 15px;
}

#CabeceraNuevoEvento{
    height: 48px;
    width: 100%;
    background: #FFF;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

}

#BoxIconoTituloNuevoEvento{
    width: 312px;
    padding-left: 16px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

#BoxIconoNuevoEvento{
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#IconoCalendarioNE{
    width: 18px;
    height: 18px;
    padding: 4px;
}

#BoxTituloAgendarNuevoEvento{
    width: 266px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

#TextoAgregarNuevoEvento{
    color: #333;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
}

#BoxIconoCerrarEvento{
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


#IconoCerrarNE{
    width: 13px;
    height: 13px;
    padding: 7px;
}

/*body evento*/

#BoxDataToCreateNuevoEvento{
    padding-top: 16px;
    width: 326px;
    padding-left: 24px;
    padding-bottom: 24px;
    padding-right: 24px;
}

#BoxInternoBodyCrearNuevoEvento{
    width: 326px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

#BoxContenedorFormularioNuevoEvento{
    width: 326px;
    height: 488px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
}

#BoxContenedorSelectTipoEvento{
    width: 326px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

#TiposEventosSelectFormControl{
    width: 326px;
    height: 48px;
}

#BoxContenedorNombrEventoNuevo{
    width: 326px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

#IconoEnNuevoEvento{
    width: 23px;
    height: 23px;
    padding: 2px;
}

#BoxContenedorFechaInicioFinEvento{
    width: 326px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

#BoxSeleccionadorDeFechaTipoDiario{
    width: 326px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

#BoxSeleccionadorDeFechaTipoEventosYRecurrentes{
    width: 326px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
}


#BoxDesdeFecha{
    width: 155px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

#BoxHastaFecha{
    width: 155px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

#BoxContenedorSeleccionadorHoraInicioFinNuevoEvento{
    width: 326px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
}


#BoxDesde{
    width: 155px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

#BoxHasta{
    width: 155px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

#BoxContenedorSelectDestinoNuevoEvento{
    width: 326px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
#DestinosEmpresa{
    width: 100%;
    height: 100%;
}

#BoxContenedorSeleccionRecurrencia{
    width: 326px;
    height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}


#BoxContenidoRecurrencia{
    width: 326px;
    height: 63px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

#lblBoxTextRecurrencia{
    width: 326px;
    height: 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
}


#IconosDias{
    width: 326px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#TextoRecurrencia{
    color: #333;
    font-family: inherit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

/*footer nuevo evento*/
#BoxContenedorBtnAccionesNuevoEvento{
    width: 326px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}




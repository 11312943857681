#BoxContenidoLoading{
    width: 324px;
    height: 228px;
    display: inline-flex;
    padding: 80px 40px 60px 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

#BoxLoadingCircular{
    width: 324px;
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#BoxTextoLoading{
    display: flex;
    width: 324px;
    height: 92px;
    flex-direction: column;
    align-items: center;
    gap: var(--2, 16px);
}

#BoxLoadingTitulo{
    width: 324px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#TituloLoading{
    color: #333;
    font-family: inherit;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
}

#BoxLoadingTexto{
    display: flex;
    height: 40px;
    width: 324px;
    justify-content: center;
    align-items: center;
}
#TextoLoading{
    color: #626262;
    text-align: center;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
}
#BoxMenuGestionUsuarios{
    width: 1264px;
    height: 656px;
    display: inline-flex;
    align-items: flex-start;
    gap: 20px;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
}

#BoxLayoutMenuGestionUsurio{
    width: 268px;
    height: 624px;
    display: flex;
    padding: var(--2, 16px) var(--none, 0px) var(--2, 16px) var(--2, 16px);
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}


#BoxLayoutOpcionVisibleMenu{
    width: 960px;
    height: 656px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#BoxCerrarModal{
    width: 928px;
    height: 48px;
    display: flex;
    padding: var(--2, 16px);
    justify-content: flex-end;
    align-items: center;
    gap: var(--3, 24px);
    align-self: stretch;
}

#BoxBotonAccionEditarUsuarioGU{
    width: 152px;
    height: 48px;
}

#BoxInformacionPersonaGUEditar{
    width: 896px;
    height: 160px;
    display: flex;
    align-items: flex-start;
    gap: 36px;
}

#BoxInformacionContactoGUEditar{
    width: 896px;
    height: 56px;
    display: flex;
    align-items: flex-start;
    gap: 36px;
}

#BoxTituloInformacionPersonalGUEditar{
    width: 180px;
    height: 24px;
    display: flex;
    padding-bottom: 24px;
    align-items: center;
}

#TxtTituloInfoPerGUEditar{
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
}

#BoxTextFieldDatosUsuarios{
    width: 680px;
    height: 160px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--6, 48px);
}

#BoxDataUsuarioNomGUEditar{
    display: flex;
    align-items: flex-start;
    gap: var(--5, 40px);
}

#BoxTextfieldNombresGU{
    width: 320px;
    height: 56px;
}

#BoxDataUsuarioRutTipoGUEditar{
    display: flex;
    align-items: flex-start;
    gap: var(--5, 40px);
}

#BoxDataContactoUsuarioGUEditar{
    width: 680px;
    height: 56px;
    display: flex;
    align-items: flex-start;
    gap: var(--5, 40px);
}

#BoxBotonEditarcorreoEditarUsuarioInformacion{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
}
#BoxDataEvento{
    width: 173px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: flex-start;
    text-align: left;
}

#NombreEvento{
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
}
#DuracionEvento{
    color: #333;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
}

#TerminoEvento{
    color: #626262;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
}

#DestinoEvento{
    color: #626262;
    font-family: inherit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}
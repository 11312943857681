#AgregarInvitadoContainer{
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 500px;
    overflow-y: auto;
    padding-bottom: 15px;
    width: 350px;
}

#AgregarInvitadoForm{
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 25px;
    overflow-y: auto;
}

#BoxBotonAgregarInvitado{
    height: 48px;
    width: 172px;
}

#BoxInvitadoNombreApellido{
    display: flex;
    flex-direction: row;
    width: 90%;
    
}

#BoxTextFieldNuevoUsuarioApellido{
    height: inherit;
    padding-right: 4px;
    width: 50%;
}

#BoxTextFieldNuevoUsuarioCorreo{
    height: inherit;
    width: 90%;
}

#BoxTextFieldNuevoUsuarioNombre{
    height: inherit;
    padding-right: 4px;
    width: 50%;
}

#BoxTextFieldNuevoUsuarioPatente{
    height: inherit;
    width: 90%;
}

#BoxTextFieldNuevoUsuarioRut{
    height: inherit;
    padding-right: 4px;
    width: 90%;
}

#TituloAgregarInvitado{
    color: #175676;
    font-size: 20px;
    font-weight: 600;
    padding-top: 10px;
}

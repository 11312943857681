#BoxCabeceraMiembrosUbicacion{
    width: 992px;
    height: 48px;
    display: flex;
    padding: var(--2, 16px) var(--2, 16px) var(--none, 0px) var(--2, 16px);
    justify-content: flex-end;
    align-items: center;
    gap: var(--3, 24px);
    align-self: stretch;
}

#BoxContenidoMiembrosUbicacion{
    width: 896px;
    height: 552px;
    display: flex;
    padding: var(--none, 0px) var(--8, 64px) var(--8, 64px) var(--8, 64px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--6, 48px);
}

#BoxTituloInformacionMiembros{
    width: 896px;    
    padding-bottom: 16px;
    border-bottom: 1px solid #333;
}


#BoxInformacionMiembros{
    width: 896px;
    height: 447px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 40px;
}


#BoxInformacionPersonalContacto{
    width: 896px;
    height: 362px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 40px;
}


#BoxInformacionPersonalUsuario{
    width: 896px;
    height: 160px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 40px;
}

#BoxTituloInformacionPersonalMiembro{
    width: 200px;
    height: 160px;

}
#BoxTextFieldsInformacionPersonalMiembro{
    width: 656px;
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 40px;
}

#BoxNombreCompletoTipoInformacionPersonalMiembro{
    width: 656px;
    height: 48px;
    display: flex;
    flex-direction: row;
    gap: 40px;
}

#BoxNombreCompletoInformacionPersonalMiembro{
    width: 305px;
    height: 48px;
}

#BoxTipoUsuarioInformacionPersonalMiembro{
    width: 305px;
    height: 48px;
}

#BoxRutPasaporteInformacionPersonalMiembro{
    width: 656px;
    height: 48px;
    display: flex;
    flex-direction: row;
    gap: 40px;
}

#BoxRutInformacionPersonalMiembro{
    width: 305px;
    height: 48px;

}
#BoxPasaporteInformacionPersonalMiembro{
    width: 305px;
    height: 48px;
}

#BoxInformacionContactoUsuario{
    width: 896px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 40px;
}

#BoxTituloInformacionContactoMiembro{
    width: 200px;
    height: 48px;

}
#BoxTextFieldsInformacionContactoMiembro{
    width: 656px;
    height: 48px;
    display: flex;
    flex-direction: row;
    gap: 40px;
}   

#BoxCorreoInformacionContactoMiembro{
    width: 305px;
    height: 48px;
}
#BoxTelefonoInformacionContactoMiembro{
    width: 305px;
    height: 48px;
}


#BoxBotonesAccionInformacionUsuario{
    width: 896px;
    height: 48px;

}

#BoxBotonesAccionInformacionUsuario{
    width: 896px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end; 
}


#TituloInformacionMiembro{
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
}
#BoxCabeceraEditarContrasena{
    width: 770px;
    height: 48px;
    display: flex;
    padding: 16px 16px 16px 24px;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
    align-self: stretch;
}

#IconoCerrar{
    width: 16px;
    height: 16px;
    padding: 12px;
}

#BoxDataUsuarioContrasena{
    width: 682px;
    height: 428px;
    display: flex;
    padding: 0px 64px 64px 64px ;
    align-items: flex-start;
    gap: 60px;
}

#DataContrasenaPerfil{
    width: 368px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}


#BoxFormEditarPass{
    width: 368px;
    height: 428px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--3, 24px);
}

#BoxBotonActualizarContrasena{
    width: 100%;
    height: 48px;
}

#IconoEnFormEditar{
    width: 24px;
    height: 24px;
    padding: 0px;
}



#boxRestablecerCorreo{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 32px;
}

#CardRecuperarCorreo{
    width: 624px;
    height: 920px;
    border-radius: 32px;
}

#CardContenidoRecuperarCorreo{
    width: 408px;
    height: 488px;
    padding-top: 212px;
    padding-bottom: 220px;
    padding-left: 108px;
    padding-right: 108px;
}

#BoxImagenCambiarCorreo{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#BoxTituloCambiarMail{
    width: 100%;
    text-align: center;
    padding-top: 48px;
}

#TextoTituloCambiarMail{
    color: #175676;
    text-align: center;
    font: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
}

#FormContrasenas{
    width: 100%;
    padding-top: 16px;
}

#errorSpanCambiarMail{
    color: #EF4537;
    font: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px
}

#BoxBotonCambiarEmail{
    width: 100%;
    height: 48px;
    padding-top: 32px;
}


#ButtonCambiar{
    border-radius: 100px;
    background: #175676;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
}

#TextoBotonCambiarMail{
    color: #FFF;
    text-align: center;
    font: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; 
    letter-spacing: 0.15px;
    text-transform: none;
}
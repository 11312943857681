#BoxBotonCierreModalInvitados{
    width: 1450px;
    height: 18px;
    padding-bottom: 24px;
    padding-left: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

#IconoCerrarModalInvitaciones{
    width: 16px;
    height: 16px;
    padding: 12px;
}

#IconoCerrarModalInvitaciones{
    width: 16px;
    height: 16px;
    padding: 12px;
}
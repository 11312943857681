#BoxBotonEnviarInvitacion{
    width: 1372px;
    padding-left: 64px;
    padding-right: 64px;
    padding-bottom: 16px;
    height: 48px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

#BoxConTenidoInvitaciones{
    width: 1372px;
    height: 500px;
    padding-left: 64px;
    padding-top: 0;
    padding-right: 64px;
    padding-bottom: 40px;
}

#BoxListaInvitados{
    width: inherit;
    height: 372px;
}

#BoxManejoNuevoInvitado{
    width: 1372px;
    height: 456px;
}

#BoxPosicionBotonEnviar{
    width: 172px;
    height: 48px;
}

#iconoEnTabla{
    width: 40px;
    height: 40px;
    padding: 0px;
}

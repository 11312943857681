#GestionUsuariosDataGrid{
    width: inherit;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;
}

#BoxSelectContainerCabeceras{
    width: auto;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: var(--3, 24px);
}


#iconoEnTablaUsuarios{
    width: 40px;
    height: 40px;
    padding: 0px;
}

#iconoEnDialogConfirmacion{
    width: 88px;
    height: 88px;
}


/* Estilos para el botón de editar */


/* Color de fondo del rectángulo interno */
.icono-editar-usuario .fondo-icono-editar-usuario {
    fill: white;
    transition: fill 0.3s;
}

/* Cambia el color de fondo al pasar el cursor */
.icono-editar-usuario:hover .fondo-icono-editar-usuario {
    fill: #1F7098;
}

/* Cambia el color del botón cuando se hace clic (mientras está activo) */
.icono-editar-usuario:active .fondo-icono-editar-usuario {
    fill: #154E6B;
}

/* Color del trazo del icono */
.icono-editar-usuario .trazo-icono-editar-usuario {
    stroke: #175676;
    transition: stroke 0.3s;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
}

/* Cambia el color del trazo al pasar el cursor */
.icono-editar-usuario:hover .trazo-icono-editar-usuario {
    stroke: white;
    transition: stroke 0.3s;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
}



/* Estilos para el botón de opciones */

/* Color de fondo del rectángulo interno */
.icono-opciones-usuario .fondo-icono-opciones-usuario {
    fill: white;
    transition: fill 0.3s;
}

/* Cambia el color de fondo al pasar el cursor */
.icono-opciones-usuario:hover .fondo-icono-opciones-usuario {
    fill: #E8EEF1;
}

/* Cambia el color del botón cuando se hace clic (mientras está activo) */
.icono-opciones-usuario:active .fondo-icono-opciones-usuario {
    fill: #1F7098;
}

/* Color del trazo del icono */
.icono-opciones-usuario .trazo-icono-opciones-usuario {
    fill: #626262;
    transition: stroke 0.3s;
    fill-rule: evenodd;
    clip-rule: evenodd;
}

/* Cambia el color del trazo al pasar el cursor */
.icono-opciones-usuario:hover .trazo-icono-opciones-usuario {
    fill: #175676;
    transition: stroke 0.3s;
}

/* Cambia el color del trazo cuando se hace clic (mientras está activo) */
.icono-opciones-usuario:active .trazo-icono-opciones-usuario {
    fill: white;
}

#BoxCabeceraNuevoHorario{
    width: 1077px;
    height: 48px;
    display: flex;
    padding: var(--2, 16px) var(--2, 16px) var(--none, 0px) var(--2, 16px);
    justify-content: flex-end;
    align-items: center;
    gap: var(--3, 24px);
    align-self: stretch;
    
}


#BoxOpcionesGestionHorario{
    width: 980px;
    height: 552px;
    display: flex;
    padding: var(--none, 0px) var(--8, 64px) var(--8, 64px) var(--8, 64px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--6, 48px);
}


#BoxAgregarNuevoHorario{
    width: 980px;
    height: 552px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--3, 24px);
}


#BoxTituloSubTituloAgregarHorario{
    width: 980px;
    height: 96px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 23px;
}



#BoxTituloOpcionGestionHorario{
    width: 980px;
    display: flex;
    height: 40px;
    padding-bottom: 16px;
    align-items: flex-start;
    align-self: stretch;
    border-bottom: 1px solid #B1B1B1;
}

#TxtTituloOpcionGestionHorario{
    color: #333;
    font-family: inherit;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 125% */
}

#BoxSubTituloOpcionGestionHorario{
    display: flex;
    width: 980px;
    height: 20px;
    padding: 10px 0px;
    align-items: center;
    align-self: stretch;
}

#TxtSubTituloOpcionGestionHorario{
    color: #8A8A8A;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
}

#TxtSubTituloOpcionGestionHorarioNuevo{
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
}

#BoxContenidoFormularoCreacionHorarioGestionHorario{
    width: 980px;
    height: 440px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}





#BoxAccionesFormiularioAgregarHorario{
    display: flex;
    height: 48px;
    width: 980px;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
}



#BoxTamanoBotonHandlerAgregarHorario{
    width: 152px;
    height: 48px;
}


#BoxContenidoTituloFormularioNuevoHorario{
    width: 980px;
    height: 380px;
    display: flex;
    align-items: flex-start;
    gap: 16px;
}

#BoxTituloOpcionNuevoHorario{
    display: flex;
    width: 180px;
    height: 24px;
    padding-bottom: 32px;
    align-items: center;

}



#BoxContenidoHorarioSemanaNuevo{
    width: 679px;
    height: 354px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

#BoxContenedorTituloDiaMasHorarioNuevo{
    width: 679px;
    height: 38px;
    display: flex;
    flex-direction: row;
    gap: 33px;
    align-items: center;
}

#BoxTituloDiaHorarioNuevo{
    width: 100px;
    height: inherit;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

#BoxContenidoInicioTerminoNuevoHorarioDia{
    width: 549px;
    height: inherit;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
}


#BoxContenidoDiaInicioNuevoHorarioDia{
    width: 265px;
    height: inherit;
}



#TextoTituloDiaHorarioNuevo{
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
}


/*prueba de formato nuevo*/
#BoxLayoutContenidoNuevoHorario{
    width: 980px;
    height: 440px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 60px;
}

#BoxInformacionNombreHorario{
    width: 100%;
    height: 55px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--5, 40px)

}

#BoxTextoInformacionNuevoHorario{
    width: 200px;
    height: 48px;
}

#BoxTexfieldNombreHorarioNuevo{
    width: 745px;
    height: 48px;
}


#BoxContenidoOpcionNuevoHorario{
    width: 980px;
    height: 140px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 5px;
}

#BoxDiaInicioFinNuevoHorario{
    width: 130px;
    height: 143px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap:16px;
}

#BoxTextoDiaHorarioNuevo{
    width: inherit; /*130px*/
    height: 35px;
    text-align: center;
    border-bottom: 1px solid #175676;
}

#BoxInicioFinNuevoHorarioPicker{
    width: inherit;
    height: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
}

#BoxHoraNuevoHorarioPicker{
    width: inherit;
    height: 48px;
}

#TextoDiaHorarioNuevo{
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
}

#IconoEnHorarios{
    width: 23px;
    height: 23px;
    padding: 6px;
}
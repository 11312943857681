#BoxFacialGestionUsuario{
    width: 894px;
    height: 530px;
    display: flex;
    padding: var(--none, 0px) var(--8, 64px) 52px var(--none, 0px);
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: var(--6, 48px);
}

#BoxTituloFacialGU{
    display: flex;
    width: 894px;
    height: 40px;
    padding-bottom: 16px;
    align-items: flex-start;
    gap: 8px;
    border-bottom: 1px solid #B1B1B1;
    flex: 1 0 0;
}

#TxtTituloFacialGU{
    color: #333;
    font-family: inherit;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
}

#BoxTablaFacialGU{
    display: flex;
    width: 893px;
    height: 424px;
    flex-direction: column;
    align-items: flex-start;
    gap: 68px;
}

#DataGridFacialGU{
    width: inherit;
    height: inherit;
}


#BoxLayoutAgregaFacialGU{
    width: 896px;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 80px;
}

#BoxImagenSelectRegistroFacialGU{
    width: 896px;
    height: 272px;
    display: flex;
    align-items: flex-start;
    gap: var(--5, 40px);
}

#ImgRegistroFacialGU{
    width: 272px;
    height: 272px;
    opacity: 0.8;
}

#BoxSelectBotonRegistroFacialGU{
    width: 584px;
    height: 152px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: var(--6, 48px);
}

#BoxSelectRegistroFacialGU{
    width: 584px;
    height: 56px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#BoxEjecutarCapturarFacialGU{
    width: 548px;
    height: 152px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: var(--6, 48px);
}

#BoxBtnEjecutarGU{
    width: 152px;
    height: 48px;
}

#BoxAccionesNuevoFacialGU{
    display: flex;
    width: 896px;
    height: 48px;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 16px;
}

#BoxBtnCancelarNuevoFacialGU{
    width: 152px;
    height: 48px;
}

#BoxBtnAgregarNuevoFacialGU{
    width: 200px;
    height: 48px;
}

#iconoEnTablaFacial{
    width: 40px;
    height: 40px;
}
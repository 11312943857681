
#BotonEventoUno{
    display: flex;
    min-height: 88px;
    padding: 4px 16px;
    border-radius: 8px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    text-transform: none;
}

#BotonEventoUno:hover{
    background: rgba(76, 76, 76, 0.08);
}

#Calendario{
    width: 52px;
    height: 72px;
    padding-top: 4px;
}

#BoxCabecera{
    border-radius: var(--1, 8px);
    width: 52px;
    height: 12px;
    border: 1px solid #333;
    border-radius: 8px 8px 0px 0px;    
}


#BoxDiaNumero{
    width: 52px;
    height: 36px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    border-radius: 0px 0px 8px 8px;    
    border: 1px solid #333;
    border-top: none;

    color: #CC261A;
    text-align: center;
    font-family: inherit;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
}

#BoxMesNumero{
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    color: #CC261A;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
}

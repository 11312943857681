#CajaTiempoReal{
    border-radius: 16px;
}

#TextoBotonTR{
    color: #626262;
    font-family: inherit;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; 
    letter-spacing: 0.1px;
    text-transform: none;
    padding-right: 50px;
    padding-left: 10px;
}

#CabeceraTR{
    width: 316px !important;
    height: 56px;
    display: flex;
    align-items: center;
    padding: 4px 4px 4px 4px;
}

#BotonCerrarTR{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 0;
}

#ModalTiempoReal{
    position: fixed !important;
    right: 29px;
    left: auto !important;
    top: 190px;
    background: #FFF;
    border-radius: 16px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
}

#BotonMostrarTiempoReal{
    position: absolute;
    right: -106px;
    top: 0px;
    margin-top: 312px;
    background: #FFF;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
    transform: rotate(-90deg);
    border-radius: 16px;
}

#TextoBotonTRAbierto{
    color: #333333;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
    letter-spacing: 0.5px;
    text-transform: none !important;
    padding-left: 8px;
}

#IconoCierreTR{
    width: 8px;
    height: 16px;
    padding: 12px 16px;
}


#SentidoRegistro{
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; 
}

#iconoRespueta{
    width: 15px !important;
    height: 15px !important;
    
}

#RespuestaAcceso{
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center !important;
    box-shadow: none;
    border: none !important;
}

#NombreUsuarioFinal{
    color: #154E6B;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; 
    letter-spacing: 0.1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

#HoraRegistroAcceso{
    color: #B1B1B1;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}

#BoxTablaTR{
    width: 276px;
    height: 500px;
    max-height: 500px;
    padding-top: 12px;
    padding-left: 24px;
    padding-right: 24px;
    overflow-y: scroll;
    overflow-x: hidden;
}

#BoxTablaTR::-webkit-scrollbar{
    width: 7.415px !important;
    background: transparent !important;
}

#BoxTablaTR::-webkit-scrollbar-track{
    background: transparent !important;
}

#BoxTablaTR::-webkit-scrollbar-thumb{
    background: #C9C9C9;
    border-radius: 16px;
    
}

#tablaTR{
    width: 100%;
    display: table;
    border-spacing: 0px;
    text-indent: 0px;
}

#bodyTR{
    width: 100%;
    display: table;
}

#TiempoRealRow{
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
}

#TiempoRealRow:hover{
    background: rgba(76, 76, 76, 0.08);
    color: #B1B1B1;
}

#TiempoRealRow:active{
    background: rgba(76, 76, 76, 0.12);
    color: #B1B1B1;
}

#flechaAbrirTR, #IconoCierreTR{
    width: 16px !important;
    height: 16px !important;
}

#IconoRelojTRCerrado, #IconoTR{
    width: 24px !important;
    height: 24px !important;
}
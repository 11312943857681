#FooterBar{
    position: absolute !important;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    background: transparent !important;
    box-shadow: none !important;
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    flex-direction: row;
}

#TextFooterApp{
    text-align: center !important;
    height: 50px;
    color: #333;
    font-family: inherit;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
}

#FooterApp{
    width: 1672px;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

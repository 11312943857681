#BoxLiveCamaras{
    width: inherit;
    height: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#GridCamarasLiveStream{
    width: inherit;
    height: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-height: 712px;
    min-height: 712px;
    overflow-y: auto;
    gap: 20px;
}

#BoxContenedorLive{
    height: 100%;
    width: 100%;
}

#BoxContenedorLiveStreamCamaras{
    width: 470px;
    height: 262px;
}

#BoxNoCamaraLiveStream{
    width: 100%;
    height: 100%;
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#TextoNoCamaraLiveStream{
    color: #fff;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
}

#BoxLayoutCamarasLiveStream{
    width: inherit;
    height: inherit;
}
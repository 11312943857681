#BoxAvatar{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
}

#BoxTituloAvatar{
    display: flex;
    min-width: 254px;
    justify-content: center;
    align-items: center;
}
#TituloAvatarUsuario{
    color: #333;
    text-align: center;
    font-family: inherit;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; 
}

#BoxContenidoAvatar{
    width: 254px;
    height: 268px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    align-self: stretch;
}


#IconosEnAvatar{
    position: absolute;
    width: 40px;
    height: 40px;
    padding: 4px;
    top: 258px;
    left: 220px;
    right: 0px;
    bottom: 0px;
}

#IconoEditarInfoPerfil{
    width: 40px;
    height: 40px;
    padding: 4px;
}

#IconoEditarInfoPerfil:hover{
    width: 40px;
    height: 40px;
    padding: 4px;
}

#IconoNuevaImagenAvatar{
    width: 40px;
    height: 40px;
    padding: 4px;
}

#BoxImagenAvatar{
    width: 180px;
    height: 180px;
    flex-shrink: 0;
    border-radius: 100px;
    background: lightgray 50%;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
}
#ImgUsuarioAvatar{
    width: 100%;
    height: 100%;
    border-radius: 100px;
}

#BoxInformacionUsuarioAvatar{
    width: 254px;
    height: 56px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
}

#BoxNombreUsuarioAvatar{
    width: 254px;
    text-align: center;
}

#NombreUsuarioAvatar{
    color: #333;
    font-family: inherit;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    text-overflow: ellipsis;
}

#BoxRolUsuarioAvatar{
    width: 100%;
    height: auto;
    text-align: center;
}

#RolUsuarioAvatar{
    color: #626262;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
}




#IconButtonEditar, #IconButtonCamara{
    width: 40px;
    height: 40px;
    padding: 8px;
}
#BoxContainerCM{
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 20px 20px 25px 20px;
}

#BoxSubirArchivoCM{
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    width: 400px ;
}

#BotonSeleccionarArchivoCM{
    border-radius: 100px;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
    height: 48px;
    color: #FFF;
    background: #175676;
    text-align: center;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    text-transform: none !important;
    width: 130px;
}

#BotonSeleccionarArchivoCM:hover{
    border-radius: 100px;
    background: #1F7098;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
    color: #B7CBD5;
    text-align: center;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
}

#BotonSubirArchivoCM{
    border-radius: 100px;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
    height: 48px;
    color: #FFF;
    text-align: center;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    text-transform: none !important;
    width: 50%;
}

#BotonSubirArchivoCM:hover{
    border-radius: 100px;
    background: #1F7098;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
    color: #B7CBD5;
    text-align: center;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
}

#TextoArchivoSeleccionadoCM{
    color: black;
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 270px;
    border: solid 1px #175676;
    height: 48px;
}

#ParrafoFormatoIdeal{
    font-size: 14px;
    color: #6B6B6B;
    margin-bottom: 10px;
    margin-bottom: 20px;
}
#BoxValidarGenerarQRVisita{
    display: flex;
    width: 624px;
    height: 920px;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 32px;
}


#BoxContenedorQRVisita{
    width: 408px;
    height: 684px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--2, 16px);
    position: absolute;
    right: 107px;
    bottom: 90px;
}

#BoxImagenMasContenidoQRVisita{
    width: 408px;
    height: 628px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--6, 48px);

}

#BoxContenedorImagenCP2QRVisita{
    width: 408px;
    height: 152px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#ImagenLogoCP2QRVisita{
    width: 256px;
    height: 152px;
}

#BoxInformacionEventoQRVisita{
    width: 408px;
    height: 428px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--4, 32px);
}

#BoxTituloNombreEventoQRVisita{
    height: 64px;
    display: flex;
    flex-direction: column;
    width: 405px;
    justify-content: center;
    align-items: center;
}

#TextoTituloNombreEventoQRVisita{
    flex: 1 0 0;
    color: #175676;
    text-align: center;
    font-family: inherit;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
}

#BoxDataDelEvento{
    width: 405px;
    height: 108px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

#TablaDataEventoQRVisita{
    width: 370px;
    height: 108px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
}

#BoxInformacionOrdenandaMasIconoQRVisita{
    width: 100%;
    height: 24px;
    display: flex;
    align-items: center;
    gap: var(--2, 16px);
}

#iconoQrEvento{
    width: 18px;
    height: 18px;
}

#TextoInformacionEventoQrVisita{
    color: #333;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

#BoxTituloAyudaInputQRVisita{
    width: 408px;
    height: 348px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--4, 16px);
}

#BoxTituloEInformacionQRVisita{
    width: 405px;
    height: 196px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--3, 24px);
}

#BoxAyudaMasInputVerifcadorQRVisita{
    width: 405px;
    display: flex;
    height: 120px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--3, 24px);

}

#BoxAccionQrVisita{
    width: 405px;
    height: 48px;
}

#BoxFooterQRVisita, #BoxInformacionAyudaQRVisita{
    width: 408px;
    height: 40px;
}

#TextoAyudaQRVisita{
    flex: 1 0 0;
    color: #333;
    text-align: center;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
}

#BoxInputIdentificadorQRVisita{
    width: 408px;
    height: 56px;
    display: flex;
    flex-direction: row;
}

#TextoVentanaObteniendoDataQRVisita{
    flex: 1 0 0;
    color: #333;
    text-align: center;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
}

#TextoVentanaObteniendoDataQRVisitaRespuestaServidor{
    flex: 1 1 0;
    background: red;
    color: #fff;
    text-align: center;
    font-family: inherit;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
}

#IconButtonIdentificador{
    height: 40px;
    padding: 0 0 0 5px;
    width: 92px;
    border-radius: 8px;
    background-color: #175676;
}

#TextoIdentificadorQRVisita{
    color: white;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.5px;
}


#BoxImagenInformacionQR{
    width: 408px;
    height: 728px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--6, 48px);
    position: absolute;
    left: 108px;
    top: 96px;
}

#BoxInformacionyQRAcceso{
    width: 408px;
    height: 533px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--2, 16px);
}

#BoxTituloQRAcceso{
    display: flex;
    width: 408px;
    height: 32px;
    justify-content: center;
    align-items: center;
}

#TextoTituloQRAcceso{
    flex: 1 0 0;
    color: #175676;
    text-align: center;
    font-family: inherit    ;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
}

#BoxContenidoParaQRAcceso{
    width: 408px;
    height: 475px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--4, 32px);
}


#BoxInfoAyudaQRAcceso{
    display: flex;
    width: 408px;
    padding: 2px 0px;
    justify-content: center;
    align-items: center;
    height: 60px;
}


#TextoAyudaQRAcceso{
    color: #333;
    flex: 1 0 0;
    text-align: center;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
}

#BoxQRAccesoInvitadoVisible{
    width: 408px;
    height:335px;
    display: flex;
    justify-content: center;
    align-items: center;
}


#BoxInfoSoloUnQRAcceso{
    display: flex;
    width: 408px;
    height: 16px;
    padding: 4px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


#TextoInfoSoloUnQRAcceso{
    flex: 1 0 0;
    color: #333;
    text-align: center;
    font-family: inherit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; 
}
#BotonEnviarInvitaciones{
    width: 200px;
    height: 48px;
    border-radius: 100px;
    background: #175676;
}

#TextoBotonEnviarInvitaciones{
    color: #FFF;
    text-align: center;
    /* TM-Roboto */
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
    text-transform: none;
}

#BoxNuevoInvitadoManual{
    width: inherit;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
}

#BoxIconoAgregarInvitado{
    display: flex;
    flex-direction: row;
    align-items: center;
}

#IconoUsuarioInvitado{
    width: 24px;
    height: 24px;
}

#BoxTextFieldNuevoUsuarioOpciones{
    padding-left: 10px;
    width: 285px;
    height: 52px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

#IconoMenuOpciones{
    width: inherit;
    height: inherit;
}

#RowCabeceraTablaInvitados{
    height: 72px !important;
}

#celdaCabecera{
    text-align: center;
    padding: 0;
}

#dataInvitado{
    height: 60px !important;
    padding: 0;
    border-bottom: 0px !important;
}

#TextoTituloAgregarInvitado{
    margin-left: 16px;
    color: #333;
    font-family: inherit;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; 
}




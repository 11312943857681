#BoxLoginFormVentanUno{
    width: 408px;
    height: 528px;
    display: flex;
    padding: 212px 108px 160px 108px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#BoxContenidoLoginFormVentanaUno{
    width: 408px;
    height: 548px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 48px;
}

#BoxContenidoImgCPLoginFormVentanaUno{
    width: 408px;
    height: 155px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#ImgLogoCPLogin{
    width: 260px;
    height: 155px;
}

#BoxContenidoFormularioLoginFormVentanaUno{
    width: 408px;
    height: 228px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

#BoxContenidoFormularioTituloLoginFormVentanaUno{
    width: 408px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#TxtTituloFormularioLoginFormVentanaUno{
    color: #175676;
    font-family: inherit;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
}

#BoxContenidoFormularioCorreoLoginFormVentanaUno{
    width: 408px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#BoxContenidoFormularioContrasenaLoginFormVentanaUno{
    width: 408px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}




#BoxContenidoBtnAccionLoginFormVentanaUno{
    width: 408px;
    height: 68px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#BoxBtnAccionIngresoLoginFormVentanaUno{
    width: 408px;
    height: 40px;
}
#BoxTextoEstadoIngreso{
    margin-top: 20px;
    width: 408px;
    height: 28px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

#TxtEstadoIngresoLoginFormVentanaUno{
    color: #333;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

/* disable input autofill select change color to lightblue */

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
    background-color: transparent !important;
}

#IconoBorrarCorreoLoginFormVentanaUno{
    width: 25px;
    height: 25px;
    padding: 7px;
}



#BoxContenidoFormularioRecordarRecuperarLoginFormVentanaUno{
    width: 408px;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#BoxCheckRememberMeLoginFormVentanaUno{
    width: 150px;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

#BoxRecuperarContrasenaLoginFormVentanaUno{
    width: 160px;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

#LinkOlvidoContrasenaLoginFormVentanaUno{
    text-transform: none;
    height: 100%;
    width: 100%;
    text-decoration: none !important;
}

#TxtOlvidoContrasenaLoginFormVentanaUno{
    color: #333;
    font-family: inherit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
}

#TxtRecordarCuenta{
    font-family: inherit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;    
    color: #333;
    margin-left: 5px;
}
#ControlLblChekRecordarmeLoginFormVentanaUno{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
}

/* recuperar form */


#BoxRecuperarFormVentanUno{
    width: 624px;
    height: 910px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#BoxHandlerGoLogin{
    width: 624px;
    height: 68px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
}
#IconoRetornarRecuperarFormVentanaUno{
    width: 40px;
    height: 40px;
    padding: 0px;
    margin: 0px;
    margin-left: 31px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#BoxLayoutRecuperarFormVentanaUno{
    width: 408px;
    height: 466px;
    padding: 150px 108px 226px 108px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}


#BoxContenidoRecuperarFormVentanaUno{
    width: 408px;
    height: 466px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 48px;
}

#BoxContenidoImagenRecuperarFormVentanaUno{
    width: 408px;
    height: 158px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#ImgLogoContenidoRecuperarFormVentanaUno{
    width: 265px;
    height: 158px;
}
#BoxContenidoFormularioRecuperarFormVentanaUno{
    width: 408px;
    height: 164px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

#BoxTituloFormularioRecuperarFormVentanaUno{
    width: 408px;
    height: 36px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#TxtTituloRecuperarContrasenaFormVentanaUno{
    color: #175676;
    font-family: inherit;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 133.333% */
}
#TxtNotaRecuperarContrasenaFormVentanaUno{
    color: #333;
    font-family: inherit;
    font-size: 14px;
    letter-spacing : 0.25px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 133.333% */
}

#BoxNotaFormularioRecuperarFormVentanaUno{
    width: 408px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

#BoxTextFieldFormularioRecuperarFormVentanaUno{
    width: 408px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}



#BoxContenidoBtnAccionFormVentanaUno{
    width: 408px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
#ContainerEditarInvitado{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: whitesmoke;
    border-radius: 15px;
}

#ContenidoEditarInvitado{
    display: flex;
    flex-direction: column;
    gap: 30px;
}

#CabeceraEditarInvitado{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #B1B1B1
}

#CuerpoEditarInvitado{
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0px 10px 0px 10px;
}

#NombreApellidosEditarEvento{
    display: flex;
    flex-direction: row;
    gap: 15px;
}

#BoxBotonEditarInvitado{
    height: 48px;
    width: 70%;
    display: flex;
    margin: auto;
    padding-bottom: 15px;
}


#BoxContenidoPopover{
    display: flex;
    flex-direction: column;
    gap: 3px;
}

#Descripcion{
    color: white;
    font-size: medium;
}

#UltimaConexion{
    color: white;
    font-size: smaller;
}

#HeaderPopover{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

}

#TituloHeader{
    color: white;
    font-size: 30px;
}

#LogoHeader{
    font-size: 50px;
    size: 50px;
    background-color: blue;
}
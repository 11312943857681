#BoxLayoutMenuGestionRapida{
    width: 268px;
    height: 624px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.30);
}

#BoxOpcion0MenuGestionRapidas{
    width: 268px;
    height: 64px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    padding-bottom: 4px;
}

#MenuOpcion0DataUsuario{
    width: 268px;
    height: 64px;
    padding: 0px;
}

#MenuBotonDataUsuario{
    width: 268px !important;
    height: 64px;
    display: flex;
    padding: 24px 16px 16px 24px;
    align-items: center;
    border-radius: 4px;
    gap: 16px;
    
}

#BoxOpcionesMenuGestionRapidas{
    width: 268px;
    height: 54px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    padding-bottom: 4px;

}

#MenuBotonDisponible{
    width: 268px !important;
    height: 54px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    gap: 16px;
    padding-bottom: 4px;
    
}

#MenuBotonOpcionDisponible{
    width: 268px;
    height: 54px;
    padding: 0px;    
    padding-bottom: 4px;
}



#MenuGUTxtNombreUsuario{
    color: #333;
    text-align: center;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
#BoxLayoutMenuOpcionGU{
    width: 894px;
    height: 530px;
    display: flex;
    padding: var(--none, 0px) var(--8, 64px) 52px var(--none, 0px);
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: var(--6, 48px);
}

#BoxTextTituloLayoutMenuOpcionGU{
    display: flex;
    width: 894px;
    height: 40px;
    padding-bottom: 16px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid #B1B1B1;
    flex: 1 0 0;
}

#TxtLayoutTituloGU{
    color: #333;
    font-family: inherit;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
}

#BoxTablaOpcionGU{
    display: flex;
    width: 893px;
    height: 424px;
    flex-direction: column;
    align-items: flex-start;
    gap: 68px;
}

#BoxTextoComentarioSinRegistros{
    width: inherit;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
}

#TxtComentarioSinRegistros{
    color: #8A8A8A;
    font-family: inherit;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
}

#IconEnTitulo{
    padding: 0;
}

#IconoAgregarNuevo{
    width: 40px;
    height: 40px;
}
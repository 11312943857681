#ReportesNavegacionBox{
    margin-bottom: 56px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    height: 48px;
    align-items: center;
    justify-content:flex-start;
    width: 1496px;
    border: 1px solid red;
}

#TipographyNavegacion{
    color: #333;
    font-family: inherit;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    min-width: 260px;
}
.ReportesNavegacion{
    border-radius: 25px !important;
    border: 1.5px solid #333;
    opacity: 0.8;
    background: #F8F8F8;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
    height: 40px;
    width: 100%;
}
#ReportesNavegacionBox{
    margin-bottom: 56px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    height: 48px;
    align-items: center;
    justify-content:center;
    width: 1496px;
}

#downloadIcon{
    border-radius: 25px;
    border: 1.5px solid #175676;
    background: #F8F8F8;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
    padding: 8px;
    /* align-items: end; */
    width: 24px;
    height: 24px;
}

.navegacionReportes{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 656px;
    justify-content: space-between;
    margin-right: 200px;
}

#iconoBuscarBox{
    border-radius: 25px;
    background:  #FFF;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
    gap: 10px;
    width: 40px;
    height: 40px;
}

#iconoBuscar{
    padding: 8px;
    width: 24px;
    height: 24px;
}

#TablaReportes{
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; 
    letter-spacing: 0.15px;
}

#iconNavegacionA{
    width:'200px'; 
    height:'24px';
}

#BoxTipographyNavegacionA{
    height: 100%;
    margin-right: 48px;
}

#iconoEditarTablaComentario{
    width: 24px;
    height: 24px;
    display: flex;
    padding: 8px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 25px;
    background:  #FFF;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
}
#iconoEditarTablaComentario:hover{
    background:  #1F7098;
    
}

#iconoVerComentario{
    border-radius: 25px;
    gap: 10px;
    width: 24px;
    height: 24px;
    color: #626262;
}

#iconoVerComentario:hover{
    background:  #1F7098;
    color: white;
}

#tituloModalVercomentario{
    color: #175676;
    /* TM-Roboto */
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
}

#contenidoModalBodyVerComentario{
    color: #626262;
    /* BM-Roboto */
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
    width: 192px;
    height: 180px;
}

#idBoxModalVerComentario{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

#idBoxCuerpoPopper{
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    text-overflow: ellipsis;
}

#trianguloComentarioPopper{
    position: relative;
    top: -240px;
    left: 236px;
}

#svgIconModalUseConfirm{
    width: 88px;
    height: 88px;
    stroke-width: 8px;
    stroke: #CC261A;
}



#BoxContenedorTablaReportes{
    width: inherit;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;
}









































/* Estilos para el botón de comentario */

/* Color de fondo del rectángulo interno */
.icono-comentario .fondo-icono-comentario {
    fill: white;
}

/* Cambia el color de fondo al pasar el cursor */
.icono-comentario:hover .fondo-icono-comentario {
    fill: #E8EEF1;
}

/* Cambia el color del botón cuando se hace clic (mientras está activo) */
.icono-comentario:active .fondo-icono-comentario {
    fill: #1F7098;
}

/* Color del trazo del icono */
.icono-comentario .trazo-icono-comentario {
    stroke: #626262;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
}

/* Cambia el color del trazo al pasar el cursor */
.icono-comentario:hover .trazo-icono-comentario {
    stroke: #1F7098;
}

/* Cambia el color del trazo cuando se hace clic (mientras está activo) */
.icono-comentario:active .trazo-icono-comentario {
    stroke: white;
}
#BoxLayoutVistaMes{
    width: inherit;
    height: 616px;
}


#BoxVistaCalendarioMes{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    max-height: 616px;
    min-width: 1550px;
    overflow-x: scroll;
    overflow-y: scroll;
    background: transparent;
}

#BoxVistaCalendarioMes::-webkit-scrollbar{
    width: 7.024px !important;
    background: transparent !important;
} 

#BoxVistaCalendarioMes::-webkit-scrollbar-track{
    background: transparent !important;
}

#BoxVistaCalendarioMes::-webkit-scrollbar-thumb{
    background: #C9C9C9;
    border-radius: 100px;
}

#BoxContenedorDiasDeSemanaRow{
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #94B1C0;
}

#BoxNombreDiaSemanaVistaMes{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    border-radius: 10px;
}

#BoxContedorDiaEventos{
    width: 189px;
    padding: 0px 10px 10px 10px;
    height: 87px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
}

#BoxContenedorDia{
    width: 189px;
    height: 20px;
    text-align: center;
}

#BoxContendorDiaEventos{
    width: 189px;
    height: 72px;    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 2px;
    max-height: 72px;
}





#txtInfoEvento{
    color: #FFF;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
    letter-spacing: 0.15px;
}

#txtNombreEvento{
    color: #FFF;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;  
    letter-spacing: 0.15px;
}

#TxtNombreDiaSemanaVistaMes{
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
    letter-spacing: 0.15px;
}

#txtDiaNombreEnSemanaVistaMes{
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; 
    letter-spacing: 0.15px;
}

#BoxSemanasRowDataEventos{
    border-bottom: 1px solid #94B1C0;
}

#BotonNumeroDiaMesVistaMes{
    padding: 0px;
}
#BoxDetalleEventos{
    width: 248px;
    height: 296px;    
    border-radius: var(--2, 16px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
}



#BoxIconBtnCerrarDE{
    width: inherit;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 48px;
}
 
 #IconoEspecialConexionConTabla{
    display: flex;
    position: absolute;
    right: -20px;
    margin-top: -35px;
}

#iconoCierreDetalleEventos{
    width: 10px;
    height: 10px;
    padding: 11px;
}

#BoxDetalleEventoCreado{
    width: 178px;
    height: 232px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

#BoxFechaNombreHoraEventoCreado{
    width: 178px;
    height: 68px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
}


#BoxFechaEventoCreado{
    width: 100%;
    height: 24px;
    text-align: center;
}
#BoxNombreEventoCreado{
    width: 100%;
    height: 24px;
    text-align: center;
}
#BoxHoraInicioTerminoEventoCreado{
    width: 100%;
    height: 20px;
    text-align: center;
}

#FechaEventoCreadoHE{
    color: #CC261A;
    text-align: center;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
}

#NombreEventoCreadoHE{
    color: #175676;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
}


#HoraInicioTerminoEventoHE{
    color: #333;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.1px;
}


#BoxCreadorDetalleEventoCreado{
    width: 178px;
    height: 160px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--borderRadius, 4px);
}

#BoxAgendoDetalleEventoCreado{
    width: 178px;
    height: 20px;
    display: flex;
    align-items: flex-start;
    gap: var(--3, 24px);
}

#AgendoDetalleEventoCreadorHE{
    color: #626262;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
}

#BoxQuienAgendoDetalleEventoCreadoHE{
    min-width: 50px;
}

#NombreCreadorDetalleEventoCreadoHE{
    color: #333;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

#BoxRutDetalleEventoCreado{
    width: 178px;
    height: 20px;
    display: flex;
    align-items: flex-start;
    gap: 52px;
    align-self: stretch;
}

#BoxTelefonoDetalleEventoCreado{
    width: 178px;
    height: 20px;
    display: flex;
    align-items: flex-start;
    gap: 18px;
    align-self: stretch;
}

#BoxDestinoDetalleEventoCreado{
    width: 178px;
    height: 20px;
    display: flex;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}

#BoxPisoDetalleEventoCreado{
    width: 178px;
    height: 20px;
    display: flex;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
}

#BoxInvitadosDetalleEventoCreado{
    width: 178px;
    height: 20px;
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}
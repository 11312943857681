

#BotonMostrarHistorialEventos{
    position: absolute;
    right: -106px;
    padding: 16px;
    top: 0px;
    margin-top: 644px;
    background: #FFF;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
    transform: rotate(-90deg);
    border-radius: 16px;
}

#TextoBotonHistorialEventos{
    color: #626262;
    font-family: inherit;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
    text-transform: none;
    padding-right: 60px;
    padding-left: 20px;
}

#CabeceraHE{
    width: 296px;
    height: 48px !important;
    display: flex;
    padding: 4px 24px 4px 4px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-bottom: 1px solid #B1B1B1;
}

#IconoCierreHE{
    width: 8px;
    height: 16px;
    padding: 12px 16px;
}

#BoxIconoCalendarioTexto{
    width: 236px;
    display: flex;
    align-items: center;
    gap: 8px;
}

#BoxCalendarioTextoHE{
    width: 180px !important;
    display: flex;
    align-items: center;
    gap: 8px;
}

#IconoCalendarioHE{
    width: 18px !important;
    height: 18px !important;
    padding: 3px;
}

#TextoBotonHEAbierto{
    color: #333;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: none;
}

#IconoBotonNuevoEvento{
    width: 40px;
    height: 40px;
}

#AgregarNuevoEvento{
    width: 14px !important;
    height: 14px !important;
    padding: 13px;
}

#BoxDataHistorialEventos{
    width: 276px;
    height: 220px;
    padding: var(--none, 0px) var(--3, 24px) var(--3, 24px) var(--3, 24px);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--3, 24px);
}

#BoxImagenSinEventos{
    width: 276px;
    height: 220px;
}

#BoxImagenNoEventos{
    width: 276px;
    height: 168px !important;
    align-self: stretch;
    padding-bottom: 16px;
}

#imageNoEventos{
   width: 100%;
   height: 100%;
   background: lightgray 0% 0% / 26.760563254356384px 26.760563254356384px repeat;
}

#BoxTextoNoEventos{
    display: flex;
    justify-content: center;
    align-items: center;
}

#TextoNoEventosRegistrados{
    color: #626262;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
}


#BoxListaEventos{
    width: 276px;
    height: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    align-self: stretch;
}

#BoxImagenUnEvento{
    width: 276px;
    padding: var(--none, 0px) var(--3, 24px) var(--3, 24px) var(--3, 24px);
    height: 120px !important;
    /*asdasdadsadsa aqui va*/
    padding-bottom: 12px;
}

#ImagenUnEvento{
    width: 276px;
    height: 120px !important;
    background: lightgray 0% 0% / 26.760563254356384px 26.760563254356384px repeat;;
}



#BoxDetalleHistorialEventos{
    text-align: center !important;
}










#BoxImagenTextoSinEventos{
    padding-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
    height: 220px !important;
}



#RelojHistorialEventosCerrado{
    width: 24px !important;
    height: 24px !important;
}

#IconoAbrirHistorialEventos{
    width: 16px !important;
    height: 16px !important;
}

#BoxEventos{
    max-height: 212px;
    overflow-y: scroll;
    overflow-x: hidden;
}



#BoxEventos::-webkit-scrollbar{
    width: 7.415px !important;
    background: transparent !important;
}

#BoxEventos::-webkit-scrollbar-track{
    background: transparent !important;
}

#BoxEventos::-webkit-scrollbar-thumb{
    background: #C9C9C9;
    border-radius: 16px;
    
}


#BoxButtonVerTodos{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#BtnVerTodos{
    padding: 0;
    margin-top: 16px;
}

#txtBtnVerTodos{
    text-transform: none;
    color: #626262;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
}

#BoxBotonModalNotificacion {
    background-color: transparent;
    margin-left: 65%;
    width: 35%;
}

#BoxCuerpoModalNotificacion {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    height: 275px;
    padding: 0px 20px 20px 20px;
    gap: 20px;
}

#BoxTituloModalNotificacion {
    width: 400px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#BoxIconoTituloModalNotificaciones{
    width: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#CabeceraModalNotificacion {
    display: flex;
    flex-direction: row;
    height: 48px;
    width: 100%;
}

#FechaModalNotificacion {
    color: #626262;
    font-family: inherit;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.25px;
    line-height: 20px;
}

#TituloModalNotificacion {
    color: #333;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    padding-left: 16px;
}


#BoxFechaCuerpoModalNotificacion{
    width: 100%;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}


#BoxMensajeCuerpoModalNotificacion{
    background-color: #F8F8F8;
    width: 100%;
    height: 145px;
    max-height: 145px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#BoxMensajeCuerpoModalNotificacion::-webkit-scrollbar{
    width: 7.024px !important;
    background: transparent !important;
} 

#BoxMensajeCuerpoModalNotificacion::-webkit-scrollbar-track{
    background: transparent !important;
}

#BoxMensajeCuerpoModalNotificacion::-webkit-scrollbar-thumb{
    background: #C9C9C9;
    border-radius: 100px;
}


#TypographyContenidoNotificacion {
    color: #333;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

#LoginLayout{
    width: 1272px;
    height: 910px;
    border-radius: 32px 32px 32px 32px; 
    background: #FFF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

#BoxContenidoLoginRecuperacionesInicioSesion{
    width: 624px;
    height: 909px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 32px;
    background-color: #FFF;
    position: absolute;
}


#BoxContenidoImagenContactoInicioSesion{
    width: 678px;
    height: 910px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: auto;
    right: 0px;
    border-radius: 0px 32px 32px 0px;
}

